import React, { useContext, useState } from 'react'
import { ArrowDown, ArrowLeft, ElectrumBlue } from 'assets/icons'
import { LanguageSwitcher } from 'common'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { H1, H3, H3i, H5 } from 'assets/images'

const Header = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // LANGUAGE
  const { locale, selectLanguage } = useContext(Context)
  const handleChange = (e) => {
    selectLanguage(e.target.value)
  }
  const defaults = require('languages/id.json')

  const [showBikeType, setShowBikeType] = useState(false)

  // ROUTES
  const routesArr = [
    {
      name: 'header_bike',
      route: '/products',
    },
    {
      name: 'header_energy',
      route: '/energy',
    },
    {
      name: 'header_getYours',
      route: '/get-yours',
    },
    {
      name: 'header_aboutUs',
      route: '/about-us',
    },
    {
      name: 'header_news',
      route: '/news',
    },
    {
      name: 'header_blog',
      route: '/blog',
    },
  ]
  const renderRouteNavigations = () => {
    return routesArr.map((item, index) => (
      <div
        key={index}
        style={{
          cursor: 'pointer',
          fontFamily: pathname.includes(item.route) ? 'Gilroy-Bold' : 'Gilroy-Medium',
          color: pathname.includes(item.route) ? '#40C0C0' : '#000',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
          borderBottom: pathname.includes(item.route) ? '0.5rem solid #1ECECA' : 'none',
          borderTop: pathname.includes(item.route) ? '0.5rem solid #fff' : 'none',
        }}
        onClick={() => {
          item.name === 'header_bike'
            ? setShowBikeType(!showBikeType)
            : navigate(item.route)
        }}
      >
        <FormattedMessage
          id={item.name}
          defaultMessage={defaults[item.name]}
        />&nbsp;
        {item.route.includes('/products') && (
          <ArrowDown width='19' height='8' />
        )}
      </div>
    ))
  }

  return (
    <div style={{
      position: 'sticky',
      top: '0',
      zIndex: '9999',
    }}>

      {/* MAIN HEADER */}
      <div style={{
        padding: '0rem 12.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        height: '5.5rem',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '1.5rem',
        }}>
          <div
            style={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxSizing: 'border-box',
              borderBottom: pathname === '/' ? '0.5rem solid #1ECECA' : 'none',
              borderTop: pathname === '/' ? '0.5rem solid #fff' : 'none',
            }}
            onClick={() => navigate('/')}
          >
            <ElectrumBlue />
          </div>
          {renderRouteNavigations()}
        </div>
      </div>

      {/* PRODUCT TAB */}
      <div style={{
        backgroundColor: '#F4F9FA',
        display: showBikeType ? 'flex' : 'none',
        justifyContent: 'center',
        columnGap: '5rem',
        paddingTop: '1rem',
        position: 'absolute',
        top: '5.5rem',
        width: '100%',
      }}>
        <div
          onClick={() => {
            navigate('/products/h5')
            setShowBikeType(false)
          }}
          style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img loading='lazy' src={H5} alt='H5' height={90} />
          <div
            style={{
              fontFamily: 'Gilroy-SemiBold',
              color: '#103856',
              marginTop: '0.75rem',
              paddingBottom: '0.75rem',
              borderBottom:'none',
            }}
          >
            ELECTRUM H5
          </div>
        </div>
        <div
          onClick={() => {
            navigate('/products/h3')
            setShowBikeType(false)
          }}
          style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img loading='lazy' src={H3} alt='H3' height={90} />
          <div
            style={{
              fontFamily: 'Gilroy-SemiBold',
              color: '#103856',
              marginTop: '0.75rem',
              paddingBottom: '0.75rem',
              borderBottom:'none',
            }}
          >
            ELECTRUM H3
          </div>
        </div>
        <div
          onClick={() => {
            navigate('/products/h3i')
            setShowBikeType(false)
          }}
          style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img loading='lazy' src={H3i} alt='H3i' height={90} />
          <div
            style={{
              fontFamily: 'Gilroy-SemiBold',
              color: '#103856',
              marginTop: '0.75rem',
              paddingBottom: '0.75rem',
              borderBottom:'none',
            }}
          >
            ELECTRUM H3i
          </div>
        </div>
        {/* <div
          onClick={() => {
            navigate('/products/h1')
            setShowBikeType(false)
          }}
          style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img loading='lazy' src={H1} alt='H1' height={90} />
          <div
            style={{
              fontFamily: 'Gilroy-SemiBold',
              color: '#103856',
              marginTop: '0.75rem',
              paddingBottom: '0.75rem',
              borderBottom:'none',
            }}
          >
            ELECTRUM H1
          </div>
        </div> */}
      </div>

    </div>
  )
}

export default Header
