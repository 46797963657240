import React, { useContext, useEffect, useState } from 'react'
import { Modal, StarRating } from 'common'
import { Collapse } from 'antd'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { ArrowRight, ArrowRightOutline, ElectrumBlue } from 'assets/icons'
import {
  AboutUs,
  BSS2,
  BSSLocation,
  BSSLocationMobile,
  BatteryCharge,
  BatteryChargeDark,
  BatteryChargeDarkV2,
  BatterySwap,
  BatterySwapDark,
  BatterySwapDarkV2,
  ElectrumPulsa1,
  ElectrumPulsa2,
  EnergyBannerMobile,
  Future1,
  Future2,
  Future4,
  Future5,
  GetNowPlaceholderMobile,
  GradientDark,
  HowTo1,
  HowTo2,
  HowTo3,
  Mileage,
  MobileKV,
  PBB,
  PSB,
  ProvenQuality,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  SmartTech,
} from 'assets/images'
import Lottie from 'react-lottie'
import { EcosystemTextMobile, HematSimulation } from 'assets/lottie'
import styled from 'styled-components'
import { CostCalculatorMobile, FooterMobile, GooglePlayButton, HeaderB2BMobile, HeaderMobile } from 'components'

const asset2Placeholder = 'https://i.postimg.cc/5t8mmyML/Frame-710.png'

const Energy = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')

  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  // TOP HEADER BAR
  const [showDropdown, setShowDropdown] = useState(false)
  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  // KV CAROUSEL
  const [currentIndex, setCurrentIndex] = useState(0)
  const KVList = [
    { img: EnergyBannerMobile },
    { img: EnergyBannerMobile },
    { img: EnergyBannerMobile },
  ]
  const renderKVCarousel = () => {
    return KVList.map((item, index) => (
      <div
        key={index}
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          src={item.img}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: 'white', marginBottom: '0.4rem' }}>
            Sistem Energi
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: 'white', marginBottom: '1rem' }}>
            Terkoneksi & Terintegrasi
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              See More <ArrowRight fill="#fff" />
            </button>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              color: 'white',
              border: '3px solid white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Get Yours <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index)
    const carousel = document.getElementById('proklamasi-kv-carousel')
    carousel.scrollTo({ left: index * carousel.clientWidth, behavior: 'smooth' })
  }
  const handleScrollCarousel = (direction) => {
    const container = document.getElementById('proklamasi-kv-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const nextIndex = (currentIndex + 1) % KVList.length
  //     setCurrentIndex(nextIndex)
  //     const carousel = document.getElementById('proklamasi-kv-carousel')
  //     carousel.scrollTo({ left: nextIndex * carousel.clientWidth, behavior: 'smooth' })
  //   }, 3000)
  //   return () => clearInterval(interval)
  // }, [currentIndex, KVList.length])

  const ColoredText = styled.span`
    background: linear-gradient(to right, #ADEEEC, #1CA6A3);
    color: transparent;
    background-clip: text;
  `

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#0E1113',
        }}
      >
        <HeaderB2BMobile />

        {/* KV CAROUSEL */}
        {/* <div style={{ marginBottom: '4rem' }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <div
              id="proklamasi-kv-carousel"
              style={{
                margin: '0 auto',
                backgroundColor: '#0E1113',
                display: 'flex',
                overflowX: 'scroll',
                scrollSnapType: 'x mandatory',
                scrollSnapStop: 'always',
              }}
              onScroll={(e) => {
                const container = e.target
                const scrollPosition = container.scrollLeft
                const containerWidth = container.clientWidth
                const newIndex = Math.round(scrollPosition / containerWidth)
                setCurrentIndex(newIndex)
              }}
            >
              {renderKVCarousel()}
              <button
                onClick={() => handleScrollCarousel(-1)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '15px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50%',
                  padding: '1px 11.5px 2.5px 11.5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: currentIndex === 0 ? 'none' : 'block',
                }}
              >
                &#8249;
              </button>
              <button
                onClick={() => handleScrollCarousel(1)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '15px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50%',
                  padding: '1px 11.5px 2.5px 11.5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: currentIndex === KVList.length-1 ? 'none' : 'block',
                }}
              >
                &#8250;
              </button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
              position: 'relative',
            }}
          >
            {KVList.map((item, index) => (
              <div
                key={index}
                onClick={() => handleIndicatorClick(index)}
                style={{
                  width: '30px',
                  height: '6.5px',
                  borderRadius: '50rem',
                  backgroundColor: currentIndex === index ? '#1ECECA' : '#4F939D',
                  margin: '0 5px',
                  cursor: 'pointer',
                }}
              ></div>
            ))}
          </div>
        </div> */}

        {/* SINGLE BANNER */}
        <div
          style={{
            scrollSnapAlign: 'start',
            backgroundSize: 'cover',
            height: '100%',
            maxWidth: '100%', 
            flexShrink: 0,
            position: 'relative',
            marginBottom: '3rem',
          }}
        >
          <img
            effect='blur'
            alt='top-banner'
            src={EnergyBannerMobile}
            loading='lazy'
            style={{
              height: '100%',
              width: '100%', 
              objectFit: 'cover',
            }}
          />
          <div style={{
            width: '100%',
            position: 'absolute',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, 10%)',
            color: 'white',
            padding: '10px 0px',
            borderRadius: '5px',
            textAlign: 'center',
          }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.5rem', color: 'white', marginBottom: '0.4rem' }}>
              Ekosistem Energi
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.5rem', color: 'white', marginBottom: '1rem' }}>
              Terkoneksi & Terintegrasi
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                See More <ArrowRight fill="#fff" />
              </button>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                color: 'white',
                border: '3px solid white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Get Yours <ArrowRight fill="#fff" />
              </button>
            </div> */}
          </div>
        </div>

        {/* FUTURE */}
        <div style={{ padding: '3.5rem 2rem', backgroundColor: '#0E1113' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', color: 'white', textAlign: 'center', marginBottom: '0.75rem' }}>
            Ekosistem Mobilitas Listrik yang Lengkap
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#6A8192', textAlign: 'center', marginBottom: '2.5rem' }}>
            Pernah kebayang gimana caranya motor listrik, baterai, dan BSS bisa ‘ngobrol’ satu sama lain? Ini bedanya! Cuma Electrum yang bikin ekosistem mobilitas listrik yang terintegrasi dan terkoneksi.
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2.5rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
              <img loading='lazy'  alt='future1' src={Future1} style={{ width: '100%' }} />
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', textAlign: 'center', marginTop: '0.5rem' }}>
                Nggak cuma jadi yang terbaik di kelasnya, baterai bersertifikat IP67 ini dilengkapi teknologi Internet-of-Things (IoT) untuk kenyamanan pengendara dan performa baterai yang maksimal.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
              <img loading='lazy'  alt='future1' src={Future2} style={{ width: '100%' }} />
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', textAlign: 'center', marginTop: '0.5rem' }}>
                Melalui aplikasi Electrum, data motor listrik divisualisasikan untuk efisiensi dan keamanan pengendara.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
              <img loading='lazy'  alt='future1' src={Future5} style={{ width: '100%' }} />
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', textAlign: 'center', marginTop: '0.5rem' }}>
                Sistem jaringan Battery Swap Station (BSS) Electrum memastikan kamu selalu mendapatkan energi yang terbaik.
                Cek lokasi BSS {' '}
                <a href='https://bit.ly/bsselectrum' target='_blank'>
                  disini.
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* BSS LOCATION */}
        <div style={{ padding: '3rem 0' }}>
          <div style={{ width: '100%', position: 'relative' }}>
            <img loading='lazy'  alt='getnow-placeholder' src={BSSLocationMobile} style={{ width: '100%' }} />
            <div style={{
              width: '75%',
              padding: '2rem',
              backgroundColor: '#142028',
              borderRadius: '0 0.75rem 0.75rem 0',
              position: 'absolute',
              top: '7.5%',
              left: '0',
              transform: 'translateY(-7.5%)',
            }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', marginBottom: '1rem' }}>
                Lokasi BSS
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', marginBottom: '2rem' }}>
                Lebih dari 250 lokasi BSS di Jadetabek siap melayani kebutuhan kamu untuk dapatkan energi baru.
              </div>
              <button
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#0B4571',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'Gilroy-SemiBold',
                  padding: '0.75rem 1.5rem',
                  borderRadius: '0.5rem',
                  fontSize: '1rem',
                  width: 'fit-content',
                }}
                onClick={() => window.open('https://bit.ly/bsselectrum', '_blank')}
              >
                Lihat Lokasi BSS <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div>

        {/* CHARGE/SWAP */}
        <div style={{ padding: '3rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', marginBottom: '1rem' }}>
            Charge Atau Swap? <br />
            Bisa Dua-duanya!
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', marginBottom: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa. <br />
              Atau perlu nge-swap di jalan? Bisa. <br />
              Mau dua-duanya? Juga bisa!
            </div>
            {/* <div>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.5rem 1rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Tell Me More <ArrowRight fill="#fff" />
              </button>
            </div> */}
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <img loading='lazy'  src={BatteryChargeDarkV2} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#142028', padding: '3.5rem 2rem 2rem 2rem', borderRadius: '1.25rem', minHeight: '3.5rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white', marginBottom: '1rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', color: '#71869C', fontSize: '0.75rem' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          <div>
            <img loading='lazy'  src={BatterySwapDarkV2} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#142028', padding: '3.5rem 2rem 2rem 2rem', borderRadius: '1.25rem', minHeight: '3.5rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white', marginBottom: '1rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', color: '#71869C', fontSize: '0.75rem' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div>

        {/* SIMPLE HOW */}
        <div style={{ padding: '3.5rem 2rem', backgroundColor: '#0E1113' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', color: 'white', textAlign: 'center', marginBottom: '0.75rem' }}>
            Temukan. Tukar. Berangkat.
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#6A8192', textAlign: 'center', marginBottom: '2.5rem' }}>
            Tukar baterai nggak pernah semudah dan secepat ini.
          </div>
          <div style={{
            display: 'flex',
            rowGap: '2.5rem',
            overflowX: 'scroll',
            columnGap: '1.5rem',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '80%', flexShrink: 0 }}>
              <img loading='lazy'  alt='howto1' src={BSS2} style={{ width: '100%' }} />
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#6A8192', textAlign: 'center' }}>
                Temukan BSS Electrum di sekitarmu melalui aplikasi Electrum.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '80%', flexShrink: 0 }}>
              <img loading='lazy'  alt='howto1' src={HowTo2} style={{ width: '100%' }} />
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#6A8192', textAlign: 'center' }}>
                Tukar baterai kamu yang habis dengan yang terisi penuh, hanya dalam hitungan detik.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '80%', flexShrink: 0 }}>
              <img loading='lazy'  alt='howto1' src={HowTo3} style={{ width: '100%' }} />
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#6A8192', textAlign: 'center' }}>
                Lanjut berangkat dengan energi yang baru dan siap gas lagi!
              </div>
            </div>
          </div>
        </div>

        {/* TRUST PARTNER */}
        <div style={{ padding: '3.5rem 2rem', backgroundColor: '#0E1113' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', color: 'white', textAlign: 'center', marginBottom: '1.5rem' }}>
            Partner Terpercaya <br />
            Melangkah Bersama
          </div>
          <img loading='lazy'  alt='trusted' src={HowTo1} style={{ width: '100%' }} />
        </div>

        {/* LOCATION */}
        {/* <div style={{ paddingBottom: '4rem' }}>
          <div style={{ width: '100%', position: 'relative' }}>
            <img loading='lazy'  alt='getnow-placeholder' src={BSSLocationMobile} style={{ width: '100%' }} />
            <div style={{
              width: '75%',
              padding: '2rem',
              backgroundColor: '#142028',
              borderRadius: '0 0.75rem 0.75rem 0',
              position: 'absolute',
              top: '7.5%',
              left: '0',
              transform: 'translateY(-7.5%)',
            }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.125rem', color: 'white', marginBottom: '1rem' }}>
                Dapatkan Sekarang!
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', marginBottom: '2.5rem' }}>
                Klik tombol di bawah ini dan rasakan sensasi eksplorasi kota dengan motor listrik keren dari Electrum.
              </div>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}>
                Lihat BSS <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div> */}

        {/* ENERGY OPTIONS */}
        {/* <div style={{
          padding: '2rem',
          marginBottom: '4rem',
          backgroundImage: `url('${GradientDark}')`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', textAlign: 'center', marginBottom: '0.75rem' }}>
            Energy Options
          </div>
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.8rem', color: 'white', textAlign: 'center', marginBottom: '2.5rem' }}>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.75rem' }}>
            <div style={{ padding: '1.5rem 2rem', backgroundColor: '#142028', borderRadius: '0.85rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
              <img loading='lazy'  alt='PBB' src={PBB} style={{ width: '100%' }} />
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#B6FCFC', marginLeft: '-1rem' }}>
                <ul>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div>Garansi baterai 5 tahun/50 ribu km</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Home Service 1 tahun</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Emergency Road Assistance 1 tahun</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ padding: '1.5rem 2rem', backgroundColor: '#142028', borderRadius: '0.85rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
              <img loading='lazy'  alt='PBB' src={PSB} style={{ width: '100%' }} />
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                <ul>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> sewa baterai selama 12 bulan</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}

        {/* ELECTRUM PULSA */}
        <div style={{ padding: '2rem 2rem 5rem 2rem', backgroundColor: '#0E1113' }}>
          {/* TOP */}
          <div>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', marginBottom: '1.125rem' }}>
              Electrum Pulsa
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', marginBottom: '1.125rem' }}>
              Electrum Pulsa (EP) adalah mekanisme pembayaran yang digunakan dalam penukaran baterai di jaringan BSS Electrum.
              <br /><br />
              Kuota EP dapat dibeli melalui Aplikasi Electrum dan akan terpotong setiap melakukan penukaran baterai di jaringan BSS Electrum.
            </div>
            <img loading='lazy'  alt='electrum-pulsa1' src={ElectrumPulsa1} style={{ width: '100%', marginBottom: '1.75rem' }} />
            {/* <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              See More <ArrowRight fill="#fff" />
            </button> */}
          </div>
          {/* BOTTOM */}
          {/* <div>
            <div style={{
              backgroundColor: '#142028',
              borderRadius: '1.1875rem',
              padding: '1.875rem',
              marginBottom: '1.875rem',
            }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: 'white', marginBottom: '0.75rem' }}>
                E-Praktis!
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', marginBottom: '1.875rem' }}>
                Anda dapat membeli EP di aplikasi Electrum melalui metode pembayaran e-wallet dan transfer bank.
              </div>
              <GooglePlayButton />
            </div>
            <img loading='lazy'  alt='electrum-pulsa2' src={ElectrumPulsa2} style={{ width: '100%' }} />
          </div> */}
        </div>

        {/* COST CALCULATOR */}
        {/* <div style={{ padding: '2rem 0' }}>
          <CostCalculatorMobile />
        </div> */}

        {/* GET NOW */}
        {/* <div style={{ paddingBottom: '4rem' }}>
          <div style={{ width: '100%', position: 'relative' }}>
            <img loading='lazy'  alt='getnow-placeholder' src={GetNowPlaceholderMobile} style={{ width: '100%' }} />
            <div style={{
              width: '75%',
              padding: '2rem',
              backgroundColor: 'white',
              borderRadius: '0 0.75rem 0.75rem 0',
              position: 'absolute',
              top: '7.5%',
              left: '0',
              transform: 'translateY(-7.5%)',
            }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.125rem', marginBottom: '1rem' }}>
                Dapatkan Sekarang!
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', marginBottom: '2.5rem' }}>
                Klik tombol di bawah ini dan rasakan sensasi eksplorasi kota dengan motor listrik keren dari Electrum.
              </div>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}>
                See More <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div> */}

        {/* CHARGE/SWAP */}
        {/* <div style={{ padding: '5.5rem 2rem 5.5rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', marginBottom: '1rem' }}>
            Charge Atau Swap? <br />
            Suka-suka Kamu
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', marginBottom: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa.
              Atau perlu nge-swap di jalan? Juga bisa.
              Bebas pilih yang kamu suka.
            </div>
            <div>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.5rem 1rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Tell Me More <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <img loading='lazy'  src={BatteryCharge} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#142028', padding: '3.5rem 2rem 2rem 2rem', borderRadius: '1.25rem', minHeight: '3.5rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white', marginBottom: '1rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', color: '#71869C', fontSize: '0.75rem' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          <div>
            <img loading='lazy'  src={BatterySwap} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#142028', padding: '3.5rem 2rem 2rem 2rem', borderRadius: '1.25rem', minHeight: '3.5rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white', marginBottom: '1rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', color: '#71869C', fontSize: '0.75rem' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div> */}

        <FooterMobile />
      </div>
    </div>
  )
}

export default Energy
