import React, { useContext } from 'react'
import { BSS2, BSSLocation2, BatteryCharge, BatteryChargeDark, BatteryChargeDarkV2, BatterySwap, BatterySwapDark, BatterySwapDarkV2, ElectrumPulsa1, ElectrumPulsa2, EnergyBanner, Future1, Future2, Future3, GradientDark, PBB, PSB, PackageBG, Placeholder2 } from 'assets/images'
import { CostCalculatorDesktop, Footer, GooglePlayButton, Header, HeaderB2B } from 'components'
import { Context } from 'helpers/language-provider'

import { HowTo1, HowTo2, HowTo3 } from 'assets/images'
import { SampleVideo } from 'assets/videos'
import { ArrowRight, Thunder } from 'assets/icons'

const Energy = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')

  // BUTTON STYLES
  const btnStyleBlue = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }
  const btnStyleWhite = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '2px solid white',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }

  return (
    <div style={{ backgroundColor: '#0E1113' }}>
      <HeaderB2B />

      {/* IMG BANNER */}
      <div style={{
        backgroundImage: `url('${EnergyBanner}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100vw',
        height: '90vh',
        display: 'flex',
      }}>
        <div style={{ 
            padding: '0 6.5rem',
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center',
        }}>
          {/* HEADLINE & SUBHEADLINE */}
          <div style={{ fontFamily: 'Gilroy-Bold', color: 'white', fontSize: '5.5rem' }}>
            Ekosistem Energi
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', color: 'white', fontSize: '2.5rem' }}>
            Terkoneksi & Terintegrasi
          </div>
          {/* BUTTONS */}
          {/* <div style={{ display: 'flex', columnGap: '1rem', marginTop: '2.5rem' }}>
            <button style={btnStyleBlue}>
              Pre-order Now <ArrowRight fill="#fff" />
            </button>
            <button style={btnStyleWhite}>
              Rent Now <ArrowRight fill="#000" />
            </button>
          </div> */}
        </div>
      </div>

      {/* FUTURE */}
      <div style={{ padding: '6rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem', color: 'white', textAlign: 'center', marginBottom: '1.25rem' }}>
          Ekosistem Mobilitas Listrik <br />
          yang Lengkap
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#71869C', textAlign: 'center', marginBottom: '3.5rem' }}>
          Pernah kebayang gimana caranya motor listrik, baterai, dan BSS bisa ‘ngobrol’ satu sama lain? Ini bedanya! Cuma Electrum yang bikin ekosistem mobilitas listrik yang terintegrasi dan terkoneksi.
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2rem', marginBottom: '2rem' }}>
          <div>
            <img loading='lazy'  alt='future1' src={Future1} style={{ width: '100%' }} />
            <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#71869C', fontSize: '1.125rem', textAlign: 'center', marginTop: '0.5rem' }}>
              Nggak cuma jadi yang terbaik di kelasnya, baterai bersertifikat IP67 ini dilengkapi teknologi Internet-of-Things (IoT) untuk kenyamanan pengendara dan performa baterai yang maksimal.
            </div>
          </div>
          <div>
            <img loading='lazy'  alt='future2' src={Future2} style={{ width: '100%' }} />
            <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#71869C', fontSize: '1.125rem', textAlign: 'center', marginTop: '0.5rem' }}>
              Melalui aplikasi Electrum, data motor listrik divisualisasikan untuk efisiensi dan keamanan pengendara.
            </div>
          </div>
        </div>
        <img loading='lazy'  alt='future3' src={Future3} style={{ width: '100%' }} />
        <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#71869C', fontSize: '1.125rem', textAlign: 'center', marginTop: '0.5rem' }}>
          Sistem jaringan Battery Swap Station (BSS) Electrum memastikan kamu selalu mendapatkan energi yang terbaik. <br />
          Cek lokasi BSS {' '}
          <a href='https://bit.ly/bsselectrum' target='_blank'>
            disini.
          </a>
        </div>
      </div>

      {/* BSS LOCATION */}
      <div style={{
        backgroundImage: `url('${BSSLocation2}')`,
        backgroundSize: 'cover',
        width: '100%',
        height: '45rem',
        position: 'relative',
        marginBottom: '1rem',
      }}>
        <div style={{
          backgroundColor: '#142028',
          borderRadius: '1.5rem',
          padding: '2rem 1.5rem',
          height: 'fit-content',
          width: '30%',
          position: 'absolute',
          right: '7.5rem',
          bottom: '7.5rem',
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: 'white' }}>
            Lokasi BSS
            <div style={{ transform: 'scale(0.65)' }}>
              <Thunder />
            </div>
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
            Lebih dari 250 lokasi BSS di Jadetabek siap melayani kebutuhan kamu untuk dapatkan energi baru.
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
            <button
              style={btnStyleBlue}
              onClick={() => window.open('https://bit.ly/bsselectrum', '_blank')}
            >
              Lihat Lokasi BSS
            </button>
          </div>
        </div>
      </div>

      {/* CHARGE/SWAP */}
      <div style={{ padding: '6.5rem 12.5rem' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: 'white' }}>
            Charge Atau Swap? <br />
            Bisa Dua-duanya!
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '45%', marginTop: '0.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa. <br />
              Atau perlu nge-swap di jalan? Bisa. <br />
              Mau dua-duanya? Juga bisa!
            </div>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2.5rem', marginTop: '2.5rem' }}>
          <div>
            <img loading='lazy'  src={BatteryChargeDarkV2} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#142028', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', color: 'white', marginBottom: '1rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          <div>
            <img loading='lazy'  src={BatterySwapDarkV2} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#142028', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', color: 'white', marginBottom: '1rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SIMPLE HOW */}
      <div style={{ padding: '6rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem', color: 'white', textAlign: 'center', marginBottom: '1.25rem' }}>
          Temukan. Tukar. Berangkat.
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center' }}>
          Tukar baterai nggak pernah semudah dan secepat ini.
        </div>
        <div style={{ display: 'flex', columnGap: '2.5rem', marginTop: '3.5rem' }}>
          <div style={{ width: '30%' }}>
            <img loading='lazy'  src={BSS2} alt='howto1' style={{ width: '100%' }} />
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginTop: '1.5rem'}}>
              Temukan BSS Electrum di sekitarmu melalui aplikasi Electrum.
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <img loading='lazy'  src={HowTo2} alt='howto1' style={{ width: '100%' }} />
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginTop: '1.5rem'}}>
              Tukar baterai kamu yang habis dengan yang terisi penuh, hanya dalam hitungan detik.
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <img loading='lazy'  src={HowTo3} alt='howto1' style={{ width: '100%' }} />
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginTop: '1.5rem'}}>
              Lanjut berangkat dengan energi yang baru dan siap gas lagi!
            </div>
          </div>
        </div>
      </div>

      {/* TRUSTED */}
      <div style={{ padding: '6rem 0rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3rem', color: 'white', textAlign: 'center', marginBottom: '3.5rem' }}>
          Partner Terpercaya Melangkah Bersama
        </div>
        <img loading='lazy'  alt='trusted' src={HowTo1} style={{ width: '100%' }} />
      </div>

      {/* BSS LOCATION */}
      {/* <div style={{
        backgroundImage: `url('${BSSLocation2}')`,
        backgroundSize: 'cover',
        width: '100%',
        height: '45rem',
        position: 'relative',
        // zIndex: '-999',
        marginBottom: '1rem',
      }}>
        <div style={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
          padding: '2rem 1.5rem',
          height: 'fit-content',
          width: '25%',
          position: 'absolute',
          right: '5.5rem',
          bottom: '5.5rem',
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: '#103856' }}>
            BSS Location
            <div style={{ transform: 'scale(0.75)' }}>
              <Thunder />
            </div>
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#103856' }}>
            Dimanapun anda pergi, akan ketemu energi dan servis. Dimanapun anda pergi, akan ketemu energi dan servis.
          </div>
        </div>
      </div> */}

      {/* ELECTRUM PULSA */}
      <div style={{ padding: '6rem 12.5rem' }}>
        {/* TOP SECTION */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', marginBottom: '3rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '45%' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: 'white' }}>
              Electrum Pulsa
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Electrum Pulsa (EP) adalah mekanisme pembayaran yang digunakan dalam penukaran baterai di jaringan BSS Electrum.
              <br /><br />
              Kuota EP dapat dibeli melalui Aplikasi Electrum dan akan terpotong setiap melakukan penukaran baterai di jaringan BSS Electrum.
            </div>
          </div>
          {/* <div>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Lihat Pilihan Paket <ArrowRight fill="#fff" />
            </button>
          </div> */}
        </div>
        <img loading='lazy'  alt='electrum-pulsa' src={ElectrumPulsa1} style={{ width: '100%' }} />
        {/* BOTTOM SECTION */}
        {/* <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
          columnGap: '3.75rem',
          marginTop: '7.5rem',
        }}>
          <div style={{
            backgroundColor: '#142028',
            borderRadius: '2rem',
            padding: '3rem',
            height: 'min-content',
          }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '2.5rem', color: 'white', marginBottom: '1rem' }}>
              E-Praktis!
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginBottom: '3rem' }}>
              Anda dapat membeli EP di aplikasi Electrum melalui metode pembayaran e-wallet dan transfer bank.
            </div>
            <GooglePlayButton />
          </div>
          <div>
            <img loading='lazy'  alt='electrum-pulsa2' src={ElectrumPulsa2} style={{ width: '100%' }} />
          </div>
        </div> */}
      </div>

      {/* CHARGE/SWAP */}
      {/* <div style={{ padding: '8rem 12.5rem' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: 'white' }}>
            Charge Atau Swap? <br />
            Suka-suka Kamu
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '45%' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa.
              Atau perlu nge-swap di jalan? Juga bisa.
              Bebas pilih yang kamu suka.
            </div>
            <div>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Tell Me More <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2.5rem', marginTop: '2.5rem' }}>
          <div>
            <img loading='lazy'  src={BatteryCharge} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#142028', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', color: 'white', marginBottom: '1rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          <div>
            <img loading='lazy'  src={BatterySwap} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#142028', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', color: 'white', marginBottom: '1rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* ENERGY OPTIONS */}
      {/* <div style={{ padding: '3rem 0rem' }}>
        <div style={{
          backgroundImage: `url('${GradientDark}')`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          padding: '5.5rem 12.5rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '3.5rem', color: '#1ECECA', textAlign: 'center', marginBottom: '1.25rem' }}>
            Energy Options
          </div>
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.25rem', color: '#71869C', textAlign: 'center', marginBottom: '3.5rem' }}>
            Tersedia 2 pilihan paket kepemilikan baterai, yang tidak mempengaruhi fleksibilitas cara kamu mengisi ulang energi (charge maupun swap).
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2.25rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
              <img loading='lazy'  alt='PBB' src={PBB} style={{ width: '100%' }} />
              <div style={{ padding: '1.5rem 2rem', backgroundColor: '#142028', borderRadius: '0.85rem', width: '70%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
                <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#B6FCFC', marginLeft: '-1rem' }}>
                  <ul>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div>Garansi baterai 5 tahun/50 ribu km</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Home Service 1 tahun</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Emergency Road Assistance 1 tahun</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
              <img loading='lazy'  alt='PSB' src={PSB} style={{ width: '100%' }} />
              <div style={{ padding: '1.5rem 2rem', backgroundColor: '#142028', borderRadius: '0.85rem', width: '70%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
                <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#B6FCFC', marginLeft: '-1rem' }}>
                  <ul>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> sewa baterai selama 12 bulan</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* COST CALCULATOR */}
      {/* <div style={{ padding: '3rem', backgroundColor: '#0E1113' }}>
        <CostCalculatorDesktop />
      </div> */}

      <Footer />
    </div>
  )
}

export default Energy
