import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { useGetBlogFromCMS } from 'apis/query'
import { ArrowRight } from 'assets/icons'
import { FooterMobile, HeaderB2BMobile, HeaderMobile } from 'components'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

const NewsMobile = () => {
  const navigate = useNavigate()

  const { data: blogData, isLoading } = useGetBlogFromCMS()

  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries('cms-blog-list')
  }, [blogData])

  const spotlightPresent = blogData?.some(data => data.type === 'spotlight')

  // SPOTLIGHT
  const renderSpotlightNews = () => {
    return blogData?.slice(0)?.reverse()?.filter(data => data.type === 'spotlight')?.map((item, index) => (
      <div key={index}>
        <img loading='lazy'  alt='spotlight' src={item.image} style={{ width: '100%', borderRadius: '0.625rem' }} />
        <div style={{
          backgroundColor: '#F4F7FA',
          borderRadius: '0.625rem',
          padding: '3rem 2rem 1.5rem 2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginTop: '-1.5rem',
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.75rem' }}>
            <div className='two-lines' style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem' }}>
              {item.title}
            </div>
            <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.9rem', color: '#707070' }}>
              <b>{item.author}</b> · {item.date}
            </div>
            <div className='three-lines' style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#414141' }}>
              {item.description}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
            <button
              onClick={() => navigate(`/blog/detail/${item.slug}`, { state: { data: item } })}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              Read More <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  // OTHER NEWS
  const renderOtherNews = () => {
    return blogData?.slice(0)?.reverse()?.filter(data => data.type === 'other')?.map((item, index) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img loading='lazy' 
          alt={`news-${item.image}`}
          src={item.image}
          style={{ width: '100%', borderRadius: '0.625rem 0.625rem 0 0' }}
        />
        <div style={{
          backgroundColor: '#F2F6F9',
          padding: '1.5rem',
          borderRadius: '0 0 0.625rem 0.625rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.75rem',
        }}>
          <div className='two-lines' style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#414141' }}>
            {item.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.9rem', color: '#707070' }}>
            <b>{item.author}</b> · {item.date}
          </div>
          <div className='three-lines' style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#414141' }}>
            {item.description}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <button
              onClick={() => navigate(`/blog/detail/${item.slug}`, { state: { data: item } })}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              Read More <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  return(
    <div>
      <HeaderB2BMobile />

      {/* SPOTLIGHT */}
      {spotlightPresent && (
        <div style={{ padding: '3rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.875rem', marginBottom: '2rem' }}>
            Artikel Pilihan
          </div>
          <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="large">
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
              {renderSpotlightNews()}
            </div>
          </Spin>
        </div>
      )}

      {/* OTHER NEWS */}
      <div style={{ padding: '3rem 2rem' }}>
        {spotlightPresent && (
          <div style={{ width: '5.5rem', height: '0.625rem', backgroundColor: '#F04C3E', marginBottom: spotlightPresent ? '1.75rem' : '1.25rem' }} />
        )}
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.875rem', marginBottom: spotlightPresent ? '2.5rem' : '2rem' }}>
          Blog Kami
        </div>
        <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="large">
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
            {renderOtherNews()}
          </div>
        </Spin>
      </div>

      <FooterMobile />
    </div>
  )
}

export default NewsMobile
