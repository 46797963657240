import React, { useContext, useEffect, useState } from 'react'
import { Modal, StarRating } from 'common'
import { Collapse } from 'antd'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import newsData from 'data/news.json'

import { ArrowRight, ArrowRightOutline, ElectrumB2B, ElectrumBlue } from 'assets/icons'
import {
  AboutUs,
  BSS,
  BSSLocation,
  BatteryCharge,
  BatteryChargeV2,
  BatterySwap,
  BatterySwapV2,
  EVBusinessMobile,
  ForBusinessKV2Mobile,
  GetNowPlaceholderMobile,
  HomeKVMobile,
  HomeKVMobile2,
  IntroH3,
  IntroH3New,
  IntroH3NewV2,
  IntroH3NewV3,
  Mileage,
  MobileKV,
  PBB,
  PSB,
  PackageBG,
  ProvenQuality,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  SepekanKV,
  SepekanKVBanner,
  SepekanKVMobile,
  SmartTech,
  Swaps,
} from 'assets/images'
import Lottie from 'react-lottie'
import { EcosystemTextMobile, HematSimulation } from 'assets/lottie'
import styled from 'styled-components'
import { CostCalculatorMobile, FooterMobile, GooglePlayButton, HeaderB2BMobile, HeaderMobile } from 'components'
import Marquee from 'react-fast-marquee'

const defaults = require('languages/id.json')

const asset2Placeholder = 'https://i.postimg.cc/5t8mmyML/Frame-710.png'

const HomeNewProduct = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')

  const navigate = useNavigate()

  const btnStyleBlue = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  // TOP HEADER BAR
  const [showDropdown, setShowDropdown] = useState(false)
  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  // KV CAROUSEL
  const [currentIndex, setCurrentIndex] = useState(0)
  const KVList = [
    { img: HomeKVMobile },
    { img: HomeKVMobile },
    { img: HomeKVMobile },
  ]
  const renderKVCarousel = () => {
    return KVList.map((item, index) => (
      <div
        key={index}
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          src={item.img}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: '#103856', marginBottom: '0.4rem' }}>
            Electrum H3i
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#103856', marginBottom: '1rem' }}>
            Bikin Perjalananmu Lebih Keren
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              See More <ArrowRight fill="#fff" />
            </button>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: 'none',
              color: '#0B4571',
              border: '3px solid #0B4571',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Get Yours <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index)
    const carousel = document.getElementById('proklamasi-kv-carousel')
    carousel.scrollTo({ left: index * carousel.clientWidth, behavior: 'smooth' })
  }
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const nextIndex = (currentIndex + 1) % KVList.length
  //     setCurrentIndex(nextIndex)
  //     const carousel = document.getElementById('proklamasi-kv-carousel')
  //     carousel.scrollTo({ left: nextIndex * carousel.clientWidth, behavior: 'smooth' })
  //   }, 3000)
  //   return () => clearInterval(interval)
  // }, [currentIndex, KVList.length])
  const handleScrollCarousel = (direction) => {
    const container = document.getElementById('proklamasi-kv-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }

  // TESTIMONY
  const testimoniesList = [
    {
      rate: 5,
      name: 'Rian Ernest',
      occupation: 'Politisi & Pengacara',
      picture: Reviewer1,
      reviewBold: '“Enak banget manuvernya,',
      review: 'terutama pas macet. Torsinya terjaga dan bentuknya compact jadi enak banget.”',
    },
    {
      rate: 5,
      name: 'Putra Pratama Purwanto',
      occupation: 'Eksekutif Muda',
      picture: Reviewer2,
      reviewBold: '"Praktis banget buat swap & go.',
      review: 'Tukar baterainya nggak lama. Terus, buat bayar energi juga simpel, bisa langsung transaksi di aplikasi.”',
    },
    {
      rate: 5,
      name: 'Alif Fajar',
      occupation: 'Pemerhati Otomotif 10+ tahun',
      picture: Reviewer3,
      reviewBold: '"Murah banget',
      review: 'ketimbang bensin! Beli 5 Electrum Pulsa (EP) seharga 15rb aja bisa buat jarak hingga 120 km."',
    },
    {
      rate: 5,
      name: 'Pak Madya',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684576/pak-madya_hhqdwl.png',
      review: '“Cocok banget, istri saya juga nyaman dibonceng. Makin manteb mau ambil H3i”',
    },
    {
      rate: 5,
      name: 'Vandy Kusumawardhana',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684576/vandy-kusumawardhana_lnetbx.png',
      review: '“Secara keseluruhan motornya enak, bagasi luas dan ternyata tukar baterai di BSS mudah dan cepat”',
    },
    {
      rate: 5,
      name: 'Rizky Anugrah Rafi',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684578/rizky-anugrah-rafi_x4zoal.png',
      review: '“Motornya enak untuk dalem kota. Buat macet-macet enak karena dimensinya cukup dan ringan. Jok enak buat berdua. Riding position enak”',
    },
    {
      rate: 5,
      name: 'Eky Nur Romadhon',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684569/eky-nur-romadhon_xr4pjf.png',
      review: '“Motornya nyaman dan irit sekali, 15ribu bisa buat 4x ganti batre. Bagasi dan deck luas, enak bisa buat bawa barang. Sendiri atau berdua nyaman”',
    },
    {
      rate: 5,
      name: 'Muhammad Iqbal Tawakal',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684574/muhammad-iqbal-tawakal_qmnwsg.png',
      review: '“Program sepekan sangat membantu bagi yang ingin merasakan experience  menggunakan motor listrik electrum dalam pengaplikasian sehari-hari apakah cocok untuk kebutuhan kita atau tidak.”',
    },
    {
      rate: 5,
      name: 'Dede Kurniawan',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1732085032/dede-kurniawan_splfjx.png',
      review: '"Enak dibawa dan enteng, untuk selap-selip enak apalagi di mode sport dan tidak mengecewakan saat dibawa"',
    },
    {
      rate: 5,
      name: 'Farouk Anoz',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1732085032/farouk-anoz_vdxgbm.png',
      review: '"Awalnya skeptis, tapi pas coba ternyata asyik juga. Dibawanya enak untuk harian dan pastinya berasa bgt hematnya dibanding BBM"',
    },
  ]
  const renderTestimonies = () => {
    return testimoniesList.map((item, index) => (
      <div
        key={index}
        style={{
          minWidth: '17.5rem',
          padding: '1.75rem',
          borderRadius: '0.5rem',
          backgroundColor: 'white',
          boxShadow: '0px 4px 20px 0px rgba(0,0,0,0.1)',
        }}
      >
        {/* TOP */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <img loading='lazy' effect='blur' src={item.picture} alt='user' width={40} style={{ borderRadius: '100%' }} />
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.2rem', marginLeft: '0.85rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', color: '#103856', fontSize: '1rem' }}>
                {item.name}
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#103856', fontSize: '0.85rem' }}>
                {item.occupation}
              </div>
              <StarRating rate={5} />
            </div>
          </div>
        </div>
        {/* SEPARATOR */}
        <div style={{ borderBottom: '0.15rem #ECECEC solid', margin: '1rem 0' }} />
        {/* REVIEW */}
        <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#103856', fontSize: '1rem' }}>
          <b>{item.reviewBold}</b>{' '}{item.review}
        </div>
      </div>
    ))
  }

  // SMART TECH
  const smartTechFeatsList = [
    {
      title: 'Sekali Tap Untuk Top Up',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Mudahnya Cari BSS',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Pantau Kondisi Motor',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Selalu Terkoneksi',
      description: 'Lorem ipsum sit dolor amet',
    },
  ]
  const renderSmartTechFeats = () => {
    return smartTechFeatsList.map((item, index) => (
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '0.85rem', color: '#1ECECA' }}>
          {item.title}
        </div>
        {/* <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#103856' }}>
          {item.description}
        </div> */}
      </div>
    ))
  }

  // LATEST NEWS
  const renderLatestNews = () => {
    const indexes = [1, 0, 4]
    const newsDataArr = indexes.map(idx => newsData[idx])
    return newsDataArr.map((item, idx) => (
      <div key={idx}>
        <img loading='lazy'  src={item.image} alt='placeholder' style={{ width: '100%', borderRadius: '0.625rem 0.625rem 0 0' }} />
        <div style={{
          backgroundColor: 'white',
          padding: '1.5rem',
          borderRadius: '0 0 0.625rem 0.625rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#103856' }}>
            {item.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#71869C', marginTop: '0.75rem' }}>
            {item.description.substring(0, 100)}...
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <button
              onClick={() => navigate(`/news/detail/${item.slug}`)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              See News <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  const ColoredText = styled.span`
    background: linear-gradient(to right, #ADEEEC, #1CA6A3);
    color: transparent;
    background-clip: text;
  `

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          backgroundColor: '#F0F8FE',
        }}
      >
        <HeaderB2BMobile />

        {/* KV CAROUSEL */}
        {/* <div style={{ marginBottom: '4rem' }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <div
              id="proklamasi-kv-carousel"
              style={{
                margin: '0 auto',
                backgroundColor: '#F0F8FE',
                display: 'flex',
                overflowX: 'scroll',
                scrollSnapType: 'x mandatory',
                scrollSnapStop: 'always',
              }}
              onScroll={(e) => {
                const container = e.target
                const scrollPosition = container.scrollLeft
                const containerWidth = container.clientWidth
                const newIndex = Math.round(scrollPosition / containerWidth)
                setCurrentIndex(newIndex)
              }}
            >
              {renderKVCarousel()}
              <button
                onClick={() => handleScrollCarousel(-1)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '15px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50%',
                  padding: '1px 11.5px 2.5px 11.5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: currentIndex === 0 ? 'none' : 'block',
                }}
              >
                &#8249;
              </button>
              <button
                onClick={() => handleScrollCarousel(1)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '15px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '50%',
                  padding: '1px 11.5px 2.5px 11.5px',
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  display: currentIndex === KVList.length-1 ? 'none' : 'block',
                }}
              >
                &#8250;
              </button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
              position: 'relative',
            }}
          >
            {KVList.map((item, index) => (
              <div
                key={index}
                onClick={() => handleIndicatorClick(index)}
                style={{
                  width: '30px',
                  height: '6.5px',
                  borderRadius: '50rem',
                  backgroundColor: currentIndex === index ? '#1ECECA' : '#4F939D',
                  margin: '0 5px',
                  cursor: 'pointer',
                }}
              ></div>
            ))}
          </div>
        </div> */}

        {/* SINGLE BANNER */}
        <div style={{ marginBottom: '2rem' }}>
          <div style={{
            scrollSnapAlign: 'start',
            backgroundSize: 'cover',
            height: '100%',
            maxWidth: '100%', 
            flexShrink: 0,
            position: 'relative',
          }}>
            <img
              effect='blur'
              alt='top-banner'
              src={ForBusinessKV2Mobile}
              loading='lazy'
              style={{
                height: '100%',
                width: '100%', 
                objectFit: 'cover',
              }}
            />
            <div style={{
              width: '100%',
              position: 'absolute',
              top: '10%',
              left: '50%',
              transform: 'translate(-50%, -10%)',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '5px',
              textAlign: 'center',
            }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: 'white', marginBottom: '0.4rem' }}>
                Electrum
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: 'white', marginBottom: '1rem' }}>
                It's a Smart Move
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', rowGap: '0.75rem' }}>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#0B4571',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'Gilroy-SemiBold',
                    padding: '0.75rem 1.5rem',
                    borderRadius: '0.5rem',
                    fontSize: '1rem',
                  }}
                  onClick={() => navigate('/products/h5')}
                >
                  Jelajah Lebih Lanjut <ArrowRight fill="#fff" />
                </button>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'white',
                    border: '3px solid white',
                    fontFamily: 'Gilroy-SemiBold',
                    padding: '0.55rem 1.5rem',
                    borderRadius: '0.5rem',
                    fontSize: '1rem',
                  }}
                  onClick={() => navigate('/products/h3i')}
                >
                  Explore H3i <ArrowRight fill="#fff" />
                </button>
              </div> */}
            </div>
          </div>
        </div>

        {/* SEPEKAN */}
        <div style={{ padding: '0 2rem', }}>
          <img loading='lazy' 
            alt='sepekan-mobile'
            src={SepekanKV}
            onClick={() => navigate('/sepekan-form')}
            // style={{ width: '100%', marginBottom: '1rem' }}
            style={{ width: '100%', marginBottom: '1rem', borderRadius: '1.125rem' }}
          />
        </div>

        {/* EV BUSINESS */}
        {/* <div style={{ padding: '4rem 0rem 3rem 0rem' }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1rem', color: '#103856', marginBottom: '1rem' }}>
              Your Reliable EV Fleet Solution
            </div>
            <ElectrumB2B height='60' />
            <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#71869C', fontSize: '0.85rem', marginTop: '1.5rem' }}>
              <b>Electrum for Business</b> menawarkan opsi <br/> sewa atau beli kendaraan listrik roda dua <br/> yang fleksibel untuk operasional bisnis <br/> Anda sekaligus mendukung keberlanjutan <br/> lingkungan.
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0rem 1.5rem 0rem' }}>
              <button style={btnStyleBlue}>
                Lihat Selengkapnya <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
          <img alt='ev-business' src={EVBusinessMobile} style={{ width: '100%' }} />
        </div> */}

        {/* INTRO H3i */}
        <div style={{ padding: '3rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.75rem', color: '#103856', marginBottom: '1.5rem' }}>
            Perkenalkan, <br /> Electrum H3i
          </div>
          <img loading='lazy'  src={IntroH3NewV3} alt='about-us' style={{ width: '100%', height: '100%', backgroundColor: 'white', borderRadius: '1.125rem', marginBottom: '1rem' }} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            rowGap: '1.5rem',
            padding: '2rem',
            backgroundColor: 'white',
            borderRadius: '1.125rem',
          }}>
            <div>
              <Marquee
                speed={35}
                style={{
                  width: '50%',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '1rem',
                  color: 'white',
                  padding: '0.6rem',
                  borderRadius: '0.3rem',
                  background: 'linear-gradient(to right, #1ECECA, #1AABB4)',
                  marginBottom: '1.5rem',
                }}
              >
                <span style={{ marginRight: '1rem' }}>
                  Motor Baru dari Electrum &nbsp; •
                </span>
              </Marquee>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', marginBottom: '0.75rem' }}>
                Electrum H3i
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#71869C' }}>
                Dengan 7 warna ekspresif, Electrum H3i kini hadir sebagai partner gaya hidup urban tanpa emisi.
              </div>
            </div>
            <div>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.875rem', marginBottom: '0.5rem' }}>
                <ColoredText>
                  Harga mulai Rp 19,900,000
                </ColoredText>
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#71869C', marginBottom: '1.5rem' }}>
                Atau cicilan Rp. 500 ribuan/bulan
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#0B4571',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'Gilroy-SemiBold',
                    padding: '0.75rem 1.5rem',
                    borderRadius: '0.5rem',
                    fontSize: '1rem',
                  }}
                  onClick={() => navigate('/get-yours')}
                >
                  Pesan Sekarang <ArrowRight fill="#fff" />
                </button>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    border: '2.5px solid #0B4571',
                    color: '#0B4571',
                    fontFamily: 'Gilroy-SemiBold',
                    padding: '0.65rem 1.3rem',
                    borderRadius: '0.5rem',
                    fontSize: '1rem',
                  }}
                  onClick={() => navigate('/products/h3i')}
                >
                  Pelajari H3i <ArrowRight fill="#fff" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ABOUT US */}
        <div style={{ padding: '3rem 0rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', margin: '0 0 1rem 2rem' }}>
            Tentang Electrum
          </div>
          <div style={{ margin: '0 2rem' }}>
            <img loading='lazy'  alt='about-us' src={AboutUs} style={{ width: '100%', borderRadius: '0.9rem', marginBottom: '1rem' }} />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              rowGap: '2rem',
              padding: '2rem 1.875rem',
              backgroundColor: 'white',
              borderRadius: '0.9rem',
            }}>
              <div>
                {/* <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.9rem', marginBottom: '1rem' }}>
                  Bangun Bersama Komunitas
                </div> */}
                <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C' }}>
                  Electrum tercipta dari <b>kolaborasi</b> yang dibangun atas masukan dan dedikasi dari semua pihak. Dengan <b>rekam jejak yang teruji</b>, kami menghadirkan motor listrik yang efisien, tangguh, dan ramah lingkungan.
                  <br/><br/>
                  Bersama, kita wujudkan masa depan yang lebih bersih dan <b>berkelanjutan</b> bagi semua.
                </div>
              </div>
              <div>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#0B4571',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'Gilroy-SemiBold',
                    padding: '0.75rem 1.5rem',
                    borderRadius: '0.5rem',
                    fontSize: '1rem',
                  }}
                  onClick={() => navigate('/about-us')}
                >
                  See More <ArrowRight fill="#fff" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* TRUST */}
        <div style={{ padding: '2rem 3rem 0rem 3rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', textAlign: 'center' }}>
            Terbukti dan Terpercaya
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', textAlign: 'center', padding: '0 1.5rem', marginTop: '1rem' }}>
            Nggak kayak yang lain, Electrum sudah buktikan sebagai solusi mobilitas roda dua yang berkualitas, efisien, dan ramah lingkungan.
          </div>
          <img loading='lazy'  src={ProvenQuality} alt='proven-quality' style={{ width: '100%', marginTop: '2.5rem' }} />
        </div>

        {/* TWO WHEELER */}
        <div style={{ backgroundColor: 'white', paddingBottom: '0.25rem' }}>
          <div style={{ padding: '2rem', display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
            <img loading='lazy'  alt='mileage' src={Mileage} style={{ maxWidth: '100%' }} />
            <img loading='lazy'  alt='riders' src={Riders} style={{ maxWidth: '100%' }} />
            <img loading='lazy'  alt='swaps' src={Swaps} style={{ maxWidth: '100%' }} />
          </div>
          {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: EcosystemTextMobile,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              }}
              width={1000}
              height={300}
              style={{
                margin: '-3rem 0rem -5rem 0rem',
              }}
            />
          </div> */}
        </div>

        {/* CHARGE/SWAP */}
        <div style={{ padding: '5rem 2rem 5rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', marginBottom: '1rem' }}>
            Charge Atau Swap? <br />
            Bisa Dua-duanya!
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', marginBottom: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa. <br />
              Atau perlu nge-swap di jalan? Bisa. <br />
              Mau dua-duanya? Juga bisa!
            </div>
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <img loading='lazy'  src={BatteryChargeV2} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: 'white', padding: '3.25rem 2rem 2rem 2rem', borderRadius: '1.25rem', minHeight: '3.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', marginBottom: '0.5rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#71869C' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <img loading='lazy'  src={BatterySwapV2} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: 'white', padding: '3.25rem 2rem 2rem 2rem', borderRadius: '1.25rem', minHeight: '3.5rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', marginBottom: '0.5rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#71869C' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
          <div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => navigate('/energy')}
            >
              Tunjukkan Saya <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>

        {/* BSS LOCATION */}
        <div style={{ backgroundColor: 'white', padding: '3rem 2rem 5rem 2rem', borderRadius: '1.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', textAlign: 'center' }}>
            Kiri-Kanan Kulihat Saja <br />
            Ada BSS Di Mana-mana
          </div>
          <div style={{ margin: '2rem 0rem', display: 'flex', justifyContent: 'center' }}>
            <img loading='lazy'  alt='bss' src={BSS} style={{ width: '100%' }} />
          </div>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1rem', color: '#103856', marginBottom: '0.75rem' }}>
            250+ BSS di Jadetabek
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', marginBottom: '1.85rem' }}>
            Ratusan BSS yang tersebar di seluruh Jadetabek siap layani kamu untuk tukar baterai dalam hitungan detik. Super sat-set!
          </div>
          <button
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}
            onClick={() => window.open('https://bit.ly/bsselectrum', '_blank')}
          >
            Cek Lokasi BSS <ArrowRight fill="#fff" />
          </button>
        </div>

        {/* PACKAGE */}
        {/* <div style={{ padding: '3rem 2rem', marginTop: '3rem', backgroundColor: 'white', borderRadius: '1.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: '#103856', textAlign: 'center', marginBottom: '1.25rem' }}>
            Opsi Pembelian Electrum H3i
          </div>
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#71869C', textAlign: 'center', marginBottom: '2.25rem' }}>
            Tersedia 2 pilihan paket kepemilikan baterai, yang tidak mempengaruhi fleksibilitas cara kamu mengisi ulang energi (charge maupun swap).
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2.5rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
              <img loading='lazy'  alt='PBB' src={PBB} style={{ width: '100%' }} />
              <div style={{ padding: '1.25rem', backgroundColor: 'white', borderRadius: '0.85rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
                <div style={{ fontFamily: 'Gilroy-Medium', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                  <ul>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div>Garansi baterai 5 tahun/50 ribu km</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Home Service 1 tahun</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Emergency Road Assistance 1 tahun</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
              <img loading='lazy'  alt='PSB' src={PSB} style={{ width: '100%' }} />
              <div style={{ padding: '1.25rem', backgroundColor: 'white', borderRadius: '0.85rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
                <div style={{ fontFamily: 'Gilroy-Medium', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                  <ul>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> sewa baterai selama 12 bulan</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* SAVE COST */}
        {/* <div style={{ padding: '6rem 2rem 2rem 2rem' }}>
          <div style={{ padding: '2rem 0rem', backgroundColor: 'white', borderRadius: '0.9rem', textAlign: 'center', marginBottom: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#0B4571', marginBottom: '1rem' }}>
              Hemat Banyak <br />
              <ColoredText>Untung Banyak</ColoredText>
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', marginBottom: '3.75rem' }}>
              Biaya Bahan Bakar Lebih <br />
              Efisien Hingga {' '}
              <span style={{ fontFamily: 'Gilroy-Bold', color: '#1ECECA' }}>
                40%
              </span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Cek Hematnya <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
          <div style={{ backgroundColor: 'white', borderRadius: '0.9rem' }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: HematSimulation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              }}
              height={350}
              width={350}
              style={{
                borderRadius: '2.5rem',
              }}
            />
          </div>
        </div> */}

        {/* COST CALCULATOR */}
        {/* <div style={{ padding: '6rem 0rem 2rem 0rem' }}>
          <CostCalculatorMobile />
        </div> */}

        {/* CHARGE/SWAP */}
        {/* <div style={{ padding: '5.5rem 3rem 5.5rem 3rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', marginBottom: '1rem' }}>
            Charge Atau Swap? <br />
            Suka-suka Kamu
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', marginBottom: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa.
              Atau perlu nge-swap di jalan? Juga bisa.
              Bebas pilih yang kamu suka.
            </div>
            <div>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.5rem 1rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Tell Me More <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
          <div style={{ marginBottom: '1.5rem' }}>
            <img loading='lazy'  src={BatteryCharge} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: 'white', padding: '3.5rem 2rem 2rem 2rem', borderRadius: '1.25rem', minHeight: '3.5rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', marginBottom: '1rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          <div>
            <img loading='lazy'  src={BatterySwap} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: 'white', padding: '3.5rem 2rem 2rem 2rem', borderRadius: '1.25rem', minHeight: '3.5rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', marginBottom: '1rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div> */}

        {/* SMART TECHNOLOGY */}
        <div style={{ padding: '4rem 0rem 4rem 0rem' }}>
          <img loading='lazy'  alt='bss-location' src={BSSLocation} style={{ width: '100%' }} />
          <div style={{
            margin: '-2rem 2rem 0rem 2rem',
            padding: '2.5rem 0rem 0rem 0rem',
            position: 'relative',
            backgroundColor: 'white',
            borderRadius: '0.85rem',
          }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.25rem', color: '#103856', marginBottom: '1.5rem', textAlign: 'center' }}>
              Teknologi yang Smart
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.85rem', marginBottom: '1.5rem' }}>
              {renderSmartTechFeats()}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <GooglePlayButton />
              </div>
            </div>
            <img loading='lazy'  alt='smart-tech' src={SmartTech} style={{ width: '100%', borderRadius: '0 0 0.85rem 0.85rem', marginBottom: '-0.5rem' }} />
          </div>
        </div>

        {/* TESTIMONIALS */}
        {/* <div style={{ padding: '4rem 0rem 2rem 0rem', backgroundColor: 'white' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', color: '#103856', paddingLeft: '2rem' }}>
            <FormattedMessage
              id="testimony"
              defaultMessage={defaults["testimony"]}
            />
          </div>
          <div style={{
            padding: '1rem 2rem',
            marginTop: '1rem',
            display: 'flex',
            overflowX: 'scroll',
            columnGap: '2rem',
            scrollPaddingLeft: '6rem',
          }}>
            {renderTestimonies()}
          </div>
        </div> */}

        {/* GET NOW */}
        <div style={{ paddingBottom: '4rem', marginTop: '6.5rem' }}>
          <div style={{ width: '100%', position: 'relative' }}>
            <img loading='lazy'  alt='getnow-placeholder' src={GetNowPlaceholderMobile} style={{ width: '100%' }} />
            <div style={{
              width: '75%',
              padding: '2rem',
              backgroundColor: 'white',
              borderRadius: '0 0.75rem 0.75rem 0',
              position: 'absolute',
              top: '7.5%',
              left: '0',
              transform: 'translateY(-7.5%)',
            }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.125rem', marginBottom: '1rem' }}>
                Dapatkan Sekarang!
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', marginBottom: '2.5rem' }}>
                Tersedia pilihan paket Electrum yang menarik melalui variasi metode pembayaran yang kamu inginkan.
              </div>
              <button
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#0B4571',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'Gilroy-SemiBold',
                  padding: '0.75rem 1.5rem',
                  borderRadius: '0.5rem',
                  fontSize: '1rem',
                  width: 'fit-content',
                }}
                onClick={() => navigate('/get-yours')}
              >
                Dapatkan H3i <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div>

        {/* DRIVER RENT */}
        {/* <div style={{ margin: '0 2rem 3rem 2rem', padding: '1.5rem', backgroundColor: 'white', borderRadius: '0.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', marginBottom: '1rem' }}>
            Sewa Khusus Mitra Driver
          </div>
          <div style={{ fontFamily: 'Gilroy-Semibold', fontSize: '0.75rem', color: '#71869C', marginBottom: '2rem' }}>
            Dapatkan penawaran khusus untuk mitra driver ojek online melalui skema penyewaan bulanan motor listrik Electrum.
          </div>
          <div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => window.open('https://opsapp.id/registrasi_electrum', '_blank')}
            >
              Sewa Electrum <ArrowRight fill="#fff" />
            </button>
          </div>
        </div> */}

        {/* LATEST NEWS */}
        <div style={{ padding: '2rem 2rem 4rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', color: '#103856', marginBottom: '2rem' }}>
            Berita Terkini
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2.5rem', marginBottom: '1.5rem' }}>
            {renderLatestNews()}
          </div>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}
              onClick={() => navigate('/news')}
            >
              See More <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>

        <FooterMobile />
      </div>
    </div>
  )
}

export default HomeNewProduct
