import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Footer, Header, HeaderB2B, Modal } from 'components'
import Webcam from 'react-webcam'
import { Button, Collapse, DatePicker, Form, Input, Progress, Select, Upload, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { ArrowRightOutline, ElectrumBlue } from 'assets/icons'
import {
  TNCSeparator,
  SepekanSquare,
} from 'assets/images'
import { CameraOutlined, UploadOutlined } from '@ant-design/icons'
import usePostRentSepekan from 'apis/api/sepekan'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import moment from 'moment'
import { useGetRentDates } from 'apis/query/sepekan-get'
import { FooterMobile, HeaderMobile } from 'components'

const videoConstraints = {
  audio: false,
  noiseSuppression: false,
  width: 320,
  height: 280,
  facingMode: "user",
  mirrored: true,
}

const audioConstraints = {
  suppressLocalAudioPlayback: true,
  noiseSuppression: true,
  echoCancellation: true,
}

const { TextArea } = Input

const SepekanForm = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  // GUIDELINES
  const guidelinesList = [
    'Kamu bisa coba pakai selama 1 minggu untuk rasakan performa dan ekosistem Electrum.',
    'Sebagai ilustrasi, jika pemakaian dimulai hari Senin, maka pengembalian di hari Senin minggu depannya.',
    'Pemakaian termasuk 1 unit motor listrik Electrum H3 dan 1 buah baterai.',
    'Biaya pemakaian sebesar Rp 250.000,- yang dibayarkan di awal pemesanan.',
    'Kamu akan diminta membayar deposit sebesar Rp 100.000 yang dapat dikembalikan setelah pengembalian unit',
    'Tersedia opsi home delivery dengan biaya tambahan.',
    'Metode pengisian energi melalui swap baterai di BSS Electrum, menggunakan Electrum Pulsa yang di-top up secara mandiri.',
    'Kamu tinggal mengisi formulir di bawah ini untuk mulai melakukan pemakaian. Yuk, pakai dan rasakan sekarang!',
    'Program Sepekan hanya berlaku bagi  customer umum dengan jangka waktu penyewaan hanya 1 minggu. Untuk Mitra Ojek Online yang membutuhkan durasi penyewaan lebih panjang, bisa melakukan pendaftaran pada link berikut ini : <a href="https://opsapp.id/registrasi_electrum" target="__blank">https://opsapp.id/registrasi_electrum</a>',
  ]
  const renderGuidelines = () => {
    return guidelinesList.map((item, index) => (
      <li style={{
        fontFamily: 'Gilroy-Semibold',
        fontSize: '0.875rem',
        color: '#71869C',
        marginBottom: guidelinesList.length-1 === index ? '0rem' : '0.5rem',
      }}>
        <span
          dangerouslySetInnerHTML={{ __html: item }}
        />
      </li>
    ))
  }
  const [acceptGuides, setAcceptGuides] = useState(false)

  // PICKUP OPTION
  const [pickupOption, setPickupOption] = useState('')

  // RENT DATE
  const [rentDate, setRentDate] = useState('')

  // ONLINE DRIVER
  const [isOnlineDriver, setIsOnlineDriver] = useState('')

  // FORM STATE
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    email_work: '',
    img_nik: '',
    img_simc: '',
    img_selfie: '',
    rent_date: rentDate,
    pickup_point: pickupOption,
    address: '',
    online_driver: isOnlineDriver,
  })

  // WEBCAM
  const [urlSelfie, setUrlSelfie] = useState(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const webcamRef = useRef(null)
  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()
    setUrlSelfie(imageSrc)
    setFormData((prevState) => ({
      ...prevState,
      // img_selfie: urlSelfie,
      img_selfie: imageSrc,
    }))
  }, [webcamRef])

  // FORM ACTIONS
  const handleChangeForm = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      img_selfie: urlSelfie,
      pickup_point: pickupOption,
      online_driver: isOnlineDriver,
      [name]: value,
    })
  }
  const handleChangeFormImage = (name) => (info) => {
    const { file } = info
    const fileObj = file.originFileObj || file
    if (fileObj) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: fileObj,
      }))
    }
  }
  const handleChangePickupOption = (option) => {
    setPickupOption(option)
    setFormData((prevData) => ({
      ...prevData,
      pickup_point: option,
    }))
  }
  const handleChangeOnlineDriver = (option) => {
    setIsOnlineDriver(option)
    setFormData((prevData) => ({
      ...prevData,
      online_driver: option,
    }))
  }
  const handleChangeRentDate = (date, dateString) => {
    setRentDate(dateString)
    setFormData((prevData) => ({
      ...prevData,
      rent_date: dateString,
    }))
  }

  // SUBMIT ACTION
  const { mutate, isLoading, uploadProgress } = usePostRentSepekan()
  const isNotFilled =
    formData.fullName === ''
    || formData.email === ''
    || formData.email_work === ''
    || formData.phoneNumber === ''
    || formData.img_nik === ''
    || formData.img_simc === ''
    || formData.img_selfie === null
    || formData.rent_date === ''
    || formData.pickup_point === ''
    || formData.online_driver === ''
  const handleSubmitForm = () => {
    const formDataToSend = new FormData()
    // Append text fields
    formDataToSend.append('fullName', formData.fullName)
    formDataToSend.append('phoneNumber', formData.phoneNumber)
    formDataToSend.append('email', formData.email)
    formDataToSend.append('email_work', formData.email_work)
    formDataToSend.append('rent_date', formData.rent_date)
    formDataToSend.append('pickup_point', formData.pickup_point)
    formDataToSend.append('address', formData.address)
    formDataToSend.append('online_driver', formData.online_driver)
    // Append files
    if (formData.img_nik) {
      formDataToSend.append('img_nik', formData.img_nik)
    }
    if (formData.img_simc) {
      formDataToSend.append('img_simc', formData.img_simc)
    }
    if (formData.img_selfie) {
      formDataToSend.append('img_selfie', formData.img_selfie)
    }
    mutate(formDataToSend)
  }

  // DATEPICKER FN
  const {
    data: dataRentDates,
    isLoading: isLoadingRentDates,
    isError: isErrorRentDates,
  } = useGetRentDates()
  const rentDatesArr = dataRentDates?.dates || []
  const dateCount = rentDatesArr?.reduce((acc, date) => {
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {})
  const disabledDates = Object.keys(dateCount)
    .filter(date => dateCount[date] >= 3) // Max is N numbers of bookings
    .map(date => moment(date))
  const disabledDate = (current) => {
    const minDate = moment().add(1, 'days').startOf('day')
    const isSunday = current && current.day() === 0
    // const isBeforeMinDate = current && current.isBefore(moment('2024-09-21'))
    const isBeforeMinDate = current && current.isBefore(moment(minDate, 'day'))
    const isSpecificDisabledDate = disabledDates.some(
      (date) => current && current.isSame(date, 'day')
    )
    return isSunday || isBeforeMinDate || isSpecificDisabledDate
  }

  return (
    <div style={{ backgroundColor: '#F4F7FA' }}>
      <HeaderB2B />

      <div style={{ padding: '3.5rem 10rem' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '1.35fr 1fr', columnGap: '2rem' }}>
          
          {/* LEFT SIDE */}
          <div>
            {/* KV */}
            <img effect='blur' alt='sepekan-square' src={SepekanSquare} style={{ width: '100%', borderRadius: '1.5rem' }} loading='lazy' />
            {/* GUIDELINES */}
            <div style={{ padding: '2rem 0rem' }}>
              <div style={{ backgroundColor: 'white', padding: '2.5rem', borderRadius: '1.5rem' }}>
                <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.25rem', color: '#103856', textAlign: 'center' }}>
                  Yuk, Ikutan SEPEKAN Electrum!
                </div>
                <div style={{ marginTop: '1rem' }}>
                  <img loading='lazy'  alt='tnc-separator' src={TNCSeparator} style={{ width: '100%', marginBottom: '0.25rem' }} />
                  <ul style={{ marginLeft: '-1.5rem', marginBottom: '0rem' }}>
                    {renderGuidelines()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* FORM SIDE */}
          <div style={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '1.5rem', height: 'fit-content' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856', marginBottom: '1.5rem' }}>
              Formulir Sepekan Electrum
            </div>
            {/* FULL NAME */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nama Lengkap sesuai KTP
              </div>
              <Input name='fullName' onChange={handleChangeForm} />
            </div>
            {/* PHONE NUMBER */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nomor Telefon
              </div>
              <Input name='phoneNumber' type='number' onChange={handleChangeForm} />
            </div>
            {/* EMAIL PRIVATE */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                E-mail Pribadi
              </div>
              <Input name='email' onChange={handleChangeForm} pattern='/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/' />
            </div>
            {/* EMAIL WORK */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                E-mail Kantor
              </div>
              <Input name='email_work' onChange={handleChangeForm} pattern='/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/' />
            </div>
            {/* NIK */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Foto NIK
              </div>
              <div style={{ display: 'flex', marginTop: '0.25rem', width: '100%' }}>
                <Form.Item
                  name='img_nik'
                  style={{ width: '100%', marginBottom: '0rem' }}
                >
                  <Upload
                    type='file'
                    maxCount={1}
                    onChange={handleChangeFormImage('img_nik')}
                    accept='image/png, image/jpg, image/jpeg'
                    beforeUpload={() => false}
                    style={{ width: '100%' }}
                    onRemove={() => setFormData({
                      ...formData,
                      img_nik: '',
                    })}
                  >
                    <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
                      Upload Foto
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
            {/* SIM C */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Foto SIM C
              </div>
              <div style={{ display: 'flex', marginTop: '0.25rem', width: '100%' }}>
                <Form.Item
                  name='img_simc'
                  style={{ width: '100%', marginBottom: '0rem' }}
                >
                  <Upload
                    type='file'
                    maxCount={1}
                    onChange={handleChangeFormImage('img_simc')}
                    accept='image/png, image/jpg, image/jpeg'
                    beforeUpload={() => false}
                    style={{ width: '100%' }}
                    onRemove={() => setFormData({
                      ...formData,
                      img_simc: '',
                    })}
                  >
                    <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
                      Upload Foto
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
            {/* SELFIE */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Foto Selfie
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  icon={<CameraOutlined />}
                  style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: showWebcam ? '0.75rem' : '0rem' }}
                  onClick={() => setShowWebcam(!showWebcam)}
                >
                  Ambil Foto
                </Button>
                {showWebcam && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {urlSelfie ? (
                      <img loading='lazy'  src={urlSelfie} alt="Screenshot" style={{ borderRadius: '0.5rem' }} />
                    ) : (
                      <Webcam
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        audioConstraints={audioConstraints}
                        style={{ borderRadius: '0.5rem' }}
                      />
                    )}
                    <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1rem' }}>
                      {urlSelfie ? (
                        <Button
                          type='primary'
                          style={{ width: '100%' }}
                          onClick={() => setUrlSelfie(null)}
                        >
                          Re-take
                        </Button>
                      ) : (
                        <Button
                          type='primary'
                          name='img_selfie'
                          style={{ width: '100%' }}
                          // onClick={() => {
                          //   capturePhoto();
                          //   handleChangeFormImage('img_selfie');
                          // }}
                          onClick={capturePhoto}
                        >
                          Capture
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* RENT DATE */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Tanggal Mulai Sewa
              </div>
              <DatePicker
                name='rent_date'
                onChange={handleChangeRentDate}
                style={{ width: '100%' }}
                disabledDate={disabledDate}
              />
            </div>
            {/* PICKUP OPTION */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.5rem' }}>
                Pengambilan Unit
              </div>
              <div style={{ display: 'flex', columnGap: '1rem' }}>
                <button
                  style={{
                    fontFamily: 'Gilroy-Bold',
                    fontSize: '0.875rem',
                    color: pickupOption === 'electrum' ? 'white' : '#0B4571',
                    backgroundColor: pickupOption === 'electrum' ? '#0B4571' : 'white',
                    padding: '0.5rem 0.85rem',
                    borderRadius: '0.375rem',
                    border: '1px solid #0B4571',
                  }}
                  onClick={() => handleChangePickupOption('electrum')}
                >
                  Ambil di Electrum
                </button>
                <button
                  style={{
                    fontFamily: 'Gilroy-Bold',
                    fontSize: '0.875rem',
                    color: pickupOption === 'home' ? 'white' : '#0B4571',
                    backgroundColor: pickupOption === 'home' ? '#0B4571' : 'white',
                    padding: '0.5rem 0.85rem',
                    borderRadius: '0.375rem',
                    border: '1px solid #0B4571',
                  }}
                  onClick={() => handleChangePickupOption('home')}
                >
                  Kirim ke Rumah
                </button>
              </div>
            </div>
            {/* ADDRESS */}
            <div style={{ marginBottom: '1.25rem', display: pickupOption === 'home' ? 'block' : 'none' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Alamat
              </div>
              <TextArea rows={4} name='address' onChange={handleChangeForm} />
            </div>
            {/* ONLINE DRIVER */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.5rem' }}>
                Apakah anda Mitra Ojek Online?
              </div>
              <Form.Item
                name='img_simc'
                style={{ width: '100%', marginBottom: '0rem' }}
              >
                <Select
                  defaultValue="Pilih opsi"
                  style={{ width: '100%' }}
                  onChange={handleChangeOnlineDriver}
                  options={[
                    { value: true, label: 'Ya' },
                    { value: false, label: 'Tidak' },
                  ]}
                />
              </Form.Item>
            </div>
            {/* SUBMIT BUTTON */}
            <div>
              <button
                style={{
                  cursor: isLoading || isNotFilled ? 'not-allowed' : 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '0.875rem',
                  color: isLoading || isNotFilled ? '#8F8F8F' : 'white',
                  backgroundColor: isLoading || isNotFilled ? '#E6E6E6' : '#0B4571',
                  border: isLoading || isNotFilled ? '2px solid #E6E6E6' : '2px solid #0B4571',
                  padding: '0.5rem 0.85rem',
                  borderRadius: '0.375rem',
                  marginBottom: isLoading || isNotFilled ? '0.5rem' : 'initial',
                }}
                onClick={isNotFilled ? () => null : handleSubmitForm}
              >
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
              {/* {isLoading && (
                <Progress percent={uploadProgress} status={isLoading ? 'active' : ''} />
              )} */}
            </div>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  )
}

export default SepekanForm
