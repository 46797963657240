import { useMutation } from 'react-query'
import axios from 'axios'
import { ELECTRUM_API_URL } from '../consts'
import { toast } from 'react-toastify'

const postRentSepekan = async (payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/user-sepekan/rent`, payload)
  return result.data
}

const usePostRentSepekan = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postRentSepekan(payload),
    onSuccess: (response) => {
      if (response.code === 201) {
        toast.success('Rent appointment has been scheduled!')
      } else if (response.code === 400) {
        toast.warn('Email and/or phone number already exists')
      } else if (response.code === 500) {
        toast.warn('An error occurred while registering user')
      }
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  return { mutate, isLoading }
}

export default usePostRentSepekan
