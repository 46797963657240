import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ElectrumWhiteMobile, WhatsappIcon } from 'assets/icons'
import { InstagramFilled, MailFilled, TikTokOutlined, WhatsAppOutlined } from '@ant-design/icons'

const FooterMobile = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <React.Fragment>

      {/* COMPONENT */}
      <div style={{
        padding: '3rem 2rem',
        backgroundColor: '#0C2536',
        // marginBottom: pathname.includes('/products') ? '5.75rem' : '0',
      }}>
        {/* SOCIALS */}
        <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1.25rem', marginBottom: '2rem' }}>
          <div
            onClick={() => window.open('https://www.instagram.com/electrum.id', '_blank')}
            style={{ padding: '0.5rem', backgroundColor: 'white', borderRadius: '100rem', cursor: 'pointer' }}
          >
            <InstagramFilled style={{ fontSize: '1.5rem', color: '#103856' }} />
          </div>
          <div
            onClick={() => window.open('https://www.tiktok.com/@electrum.indonesia', '_blank')}
            style={{ padding: '0.5rem', backgroundColor: 'white', borderRadius: '100rem', cursor: 'pointer' }}
          >
            <TikTokOutlined style={{ fontSize: '1.5rem', color: '#103856' }} />
          </div>
          <div
            onClick={() => window.open('/')}
            style={{ padding: '0.5rem', backgroundColor: 'white', borderRadius: '100rem', cursor: 'pointer' }}
          >
            <MailFilled style={{ fontSize: '1.5rem', color: '#103856' }} />
          </div>
          <div
            onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
            style={{ padding: '0.5rem', backgroundColor: 'white', borderRadius: '100rem', cursor: 'pointer' }}
          >
            <WhatsAppOutlined style={{ fontSize: '1.5rem', color: '#103856' }} />
          </div>
        </div>
        {/* NAVIGATIONS */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '3rem', marginBottom: '3rem' }}>
          {/* PAGES */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.55rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: 'white', marginBottom: '0.45rem' }}>
              Electrum
            </div>
            <div
              onClick={() => navigate('/')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Beranda
            </div>
            <div
              onClick={() => navigate('/products/h3i')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Produk
            </div>
            <div
              onClick={() => navigate('/energy')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Energi
            </div>
            <div
              onClick={() => navigate('/get-yours')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Dapatkan Milikmu
            </div>
            <div
              onClick={() => navigate('/about-us')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Tentang Kami
            </div>
            <div
              onClick={() => navigate('/news')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Berita
            </div>
            <div
              onClick={() => navigate('/blog')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Blog
            </div>
          </div>
          {/* OTHERS */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.55rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: 'white', marginBottom: '0.45rem' }}>
              Quick Links
            </div>
            <div
              onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Book Test Ride
            </div>
            <div
              onClick={() => navigate('/privacy-policy')}
              style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#4D7FA4', cursor: 'pointer' }}
            >
              Privacy Policy
            </div>
          </div>
        </div>
        {/* DIVIDER */}
        <div style={{ border: '0.675px solid #3C6889', marginBottom: '1.875rem' }} />
        {/* BOTTOM */}
        <div>
          <ElectrumWhiteMobile width='180' height='45' />
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white', marginTop: '0.5rem' }}>
            It's a Smart Move
          </div>
        </div>
      </div>

      {/* WHATSAPP */}
      <div
        style={{
          position: 'fixed',
          // bottom: pathname.includes('/products') ? '6.75rem' : '0.85rem',
          bottom: '0.85rem',
          right: '0.85rem',
          zIndex: 999,
          cursor: 'pointer',
        }}
        onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
      >
        <WhatsappIcon width='70' height='70' />
      </div>

    </React.Fragment>
  )
}

export default FooterMobile
