import { FooterMobile, HeaderB2BMobile, HeaderMobile } from 'components'
import React from 'react'

const PrivacyPolicy = () => {
  const heading = {
    fontFamily: 'Gilroy-Bold',
    fontSize: '1.5rem',
    textAlign: 'center',
  }

  const subHeading = {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1.25rem',
    textAlign: 'center',
  }

  const description = {
    fontFamily: 'Gilroy-Medium',
    fontSize: '0.85rem',
  }

  const ul = {
    marginTop: '0rem',
    paddingLeft: '1.25rem',
  }

  const pointTitle = {
    fontFamily: 'Gilroy-Bold',
    fontSize: '1.125rem',
    marginLeft: '1rem',
  }

  const sectionGap = { marginBottom: '1.5rem' }

  const subSectionGap = { marginBottom: '1rem' }

  return (
    <div>
      <HeaderB2BMobile />

      <div style={{ padding: '2rem 2rem' }}>
        {/* TITLE */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...heading }}>
            Kebijakan Privasi
          </div>
          <div style={{ ...subHeading, marginTop: '0.5rem' }}>
            3 Oktober 2023
          </div>
        </div>

        {/* INTRODUCTION */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...description, ...subSectionGap }}>
            Kebijakan Privasi ini menggambarkan bagaimana Electrum (PT Energi Kreasi Bersama dan afiliasi-afiliasinya, untuk selanjutnya disebut <b>“Electrum”</b> atau <b>“kami”</b>) mengumpulkan, menyimpan, menggunakan, memproses, menahan, transfer, membuka, dan melindungi informasi pribadi penggunanya (selanjutnya disebut “<b>Anda</b>” atau “<b>pengguna</b>”. Kebijakan Privasi ini berlaku untuk semua pengguna-pengguna dari aplikasi-aplikasi, website-website, layanan-layanan, stasiun penukaran baterai (<i>Battery Swapping Station</i> - <b>“BSS”</b>), baterai-baterai, atau produk-produk kami yang lain (<b>“Layanan”</b>)).
            <br/><br/>
            Electrum merupakan suatu perseroan terbatas yang didirikan berdasarkan Akta Pendirian Perseroan Terbatas PT Energi Kreasi Bersama No. 13 tertanggal 6 Desember 2021 yang dibuat di hadapan Aulia Taufani, notaris di Jakarta Selatan, pemegang Nomor Induk Berusaha 1001220038146.
            <br/><br/>
            Dengan menggunakan Layanan, Anda memahami dan menyetujui Kebijakan Privasi ini. Kebijakan Privasi ini harus dibaca sebagai satu kesatuan instrumen dengan ketentuan-ketentuan lain yang berlaku, termasuk Syarat dan Ketentuan (<i>Terms of Use</i>) kami. Semua istilah dengan huruf besar di dalam Kebijakan Privasi ini akan memiliki arti yang sama dengan yang diberikan kepadanya dalam Syarat dan Ketentuan.
          </div>
          <div style={{ ...description }}>
            Topik
            <ul style={{ ...ul }}>
              <li>Data apa yang kami kumpulkan?</li>
              <li>Bagaimana kami mengumpulkan data Anda?</li>
              <li>Bagaimana kami menggunakan data Anda?</li>
              <li>Bagaimana kami menyimpan data Anda?</li>
              <li>Bagaimana kami mengamankan data Anda?</li>
              <li>Pemasaran</li>
              <li>Apa hak perlindungan data Anda?</li>
              <li>Apa itu cookies?</li>
              <li>Bagaimana kami menggunakan cookies?</li>
              <li>Cookies seperti apa yang kami gunakan?</li>
              <li>Bagaimana kami mengatur cookies Anda?</li>
              <li>Kebijakan Privasi situs lain</li>
              <li>Perubahan pada Kebijakan Privasi kami</li>
              <li>Ketentuan Bahasa</li>
              <li>Bagaimana cara menghubungi kami</li>
            </ul>
          </div>
        </div>

        {/* SECTION A */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            A. Data apa yang kami kumpulkan?
          </div>
          <div style={{ ...description }}>
            Kami dapat, namun tidak selalu, mengumpulkan data-data yang disebutkan dalam bagian A ini. Data-data akan kami kumpulkan tergantung dari penggunaan Anda atas Layanan, sebagai berikut:
            <br/><br/>
            a.	Untuk membuat akun Anda, kami dapat mengumpulkan data-data yang berhubungan dengan suatu individual untuk mengidentifikasi dan memverifikasi Anda atau individu lain, seperti misalnya nama lengkap, Nomor Induk Kependudukan, <i>copy</i> KTP atau identitas lain beserta informasi di dalamnya, foto diri Anda, data biometrik Anda, jenis kelamin, kewarganegaraan, alamat tempat tinggal, nomor handphone, alamat surat elektronik (<i>email</i>), data-data lain yang dapat digunakan untuk mengidentifikasi suatu individual, metode pembayaran yang Anda pilih beserta informasi yang diperlukan untuk Anda melakukan pembayaran.
            <br/><br/>
            b.	Untuk memberikan Layanan kepada Anda dan untuk mengembangkan Layanan kami, kami dapat mengumpulkan data-data seperti misalnya data-data untuk mengidentifikasi dan memverifikasi Anda atau suatu individu dalam poin a. di atas, identitas dan spesifikasi kendaraan, muatan listrik dalam baterai, kecepatan berkendaraan, cara berkendara, kondisi kendaraan dan/atau baterai, lokasi kendaraan, perangkat yang digunakan, dan/atau baterai, identitas dan spesifikasi dan kondisi perangkat yang Anda gunakan, rekaman foto atau video pada area BSS, cara Anda menggunakan BSS, cara Anda menggunakan aplikasi, website atau Layanan kami, kebiasaan Anda menggunakan internet sebagaimana dikumpulkan melalui <i>cookies</i> browser Anda, informasi-informasi lain yang Anda berikan melalui survei-survei yang dilakukan secara sukarela.
            <br/><br/>
            c.	Dalam hal Anda bermaksud untuk mengambil suatu pembiayaan, kami juga dapat mengumpulkan data-data untuk memastikan pembayaran kembali pembiayaan tersebut, seperti misalnya, data-data dalam poin a. dan poin b. di atas, lokasi-lokasi yang biasa dikunjungi kendaraan dan/atau baterai, alamat tempat tinggal atau tempat bekerja Anda, atau informasi-informasi lain yang berhubungan dengan Anda yang secara jelas kami minta dan yang Anda berikan.
          </div>
        </div>

        {/* SECTION B */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            B. Bagaimana kami mengumpulkan data Anda?
          </div>
          <div style={{ ...description }}>
            Hampir semua data yang kami kumpulkan akan diberikan langsung oleh Anda. Kami mengumpulkan data ketika Anda menginput data-data dalam aplikasi atau Layanan, ketika Anda memberikan informasi kepada kami, pada saat Anda melakukan verifikasi ketika akan menukarkan baterai (melalui metode apapun), juga ketika Anda mengirimkan kami surat elektronik.
            <br/><br/>
            Kami dapat mengumpulkan data-data tanpa secara eksplisit menanyakan atau memintanya kepada Anda ketika Anda berkendara dengan menggunakan baterai kami, ketika Anda berada di area BSS, ketika Anda menggunakan BSS, dan juga ketika Anda menggunakan Layanan.
            <br/><br/>
            Kami juga dapat mengumpulkan data pada baterai dan/atau kendaraan sekalipun tidak sedang digunakan, data dari aplikasi dan/atau Layanan ketika aplikasi dan/atau Layanan berjalan walaupun tidak sedang digunakan (<i>on background/on standby</i>).
            <br/><br/>
            Kami juga dapat mengumpulkan data secara tidak langsung melalui program analisis dan/atau aplikasi pihak ketiga.
          </div>
        </div>

        {/* SECTION C */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            C. Bagaimana kami menggunakan data Anda?
          </div>
          <div style={{ ...description }}>
            Kami mengumpulkan data Anda agar kami dapat menjalankan Layanan kami, mengelola akun Anda, mengirimkan surat elektronik kepada Anda yang berisi informasi mengenai Layanan kami atau penawaran-penawaran khusus terkait produk-produk yang terafiliasi atau bekerjasama dengan kami, menampilkan produk-produk dari pihak yang terafiliasi atau bekerja sama dengan Electrum, menampilkan informasi seperti lokasi, foto, identitas dan kondisi kendaraan, baterai, dan/atau Anda kepada pihak pembiayaan yang Anda setujui, menganalisa data Anda untuk mengembangkan Layanan dan produk-produk dalam group Electrum.
            <br/><br/>
            Kami juga dapat menunjukkan data Anda kepada otoritas terkait dalam hal terdapat permintaan dari otoritas tersebut atau diperlukan untuk keperluan persidangan atau penyelesaian sengketa, atau diminta berdasarkan ketentuan undang-undang.
          </div>
        </div>

        {/* SECTION D */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            D. Bagaimana kami menyimpan data Anda?
          </div>
          <div style={{ ...description }}>
            Kami menyimpan data Anda di pusat data kami dan/atau rekanan kami. Kami akan menyimpan data Anda selama Anda masih menggunakan Layanan dan untuk jangka waktu setidaknya 5 tahun setelah Anda berhenti menggunakan Layanan. Kami dapat menghapus data Anda setelah jangka waktu tersebut.
            <br/><br/>
            Kami dapat menggunakan pusat data dan pusat pemulihan data (<i>data recovery centers</i>) pihak ketiga dan kami dapat menggunakan pusat data dan pusat pemulihan data yang berlokasi di luar Indonesia untuk menyimpan dan mengolah data Anda.
          </div>
        </div>

        {/* SECTION E */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            E. Bagaimana kami mengamankan data Anda?
          </div>
          <div style={{ ...description }}>
            Kami menggunakan semua tindakan yang wajar untuk melindungi dan mengamankan data Anda dari akses, pengambilan, penggunaan atau pembukaan oleh pihak yang tidak berwenang dan pengolahan data yang melanggar hukum, kehilangan tidak disengaja, kehancuran dan kerusakan atau risiko semacamnya. Namun, pengiriman informasi melalui Internet tidak secara penuh aman. Walaupun kami akan melakukan hal yang terbaik untuk melindungi data Anda, Anda mengakui bahwa kami tidak dapat memberikan jaminan integritas dan keakurasian data apapun yang Anda kirimkan melalui Internet atau menjamin bahwa data tersebut tidak akan dihadang, diakses, dibuka, diubah atau dihancurkan oleh pihak ketiga yang tidak berwenang, karena faktor di luar kuasa kami. Anda bertanggung jawab untuk menjaga detail akun Anda rahasia dan Anda tidak akan membagikan detail akun Anda, termasuk kata sandi Anda dan <i>One Time Password</i> (OTP), kepada siapapun dan Anda akan selalu menjaga dan bertanggung jawab penuh atas keamanan perangkat yang Anda gunakan.
          </div>
        </div>

        {/* SECTION F */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            F. Pemasaran
          </div>
          <div style={{ ...description }}>
            Kami bermaksud untuk mengirimkan dan/atau menampilkan informasi mengenai produk-produk dan layanan-layanan afiliasi dan rekanan kami yang mungkin Anda tertarik. Anda dapat mengakhiri pemberitahuan informasi-informasi ini pada saat kapanpun dengan menghubungi kami.
          </div>
        </div>

        {/* SECTION G */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            G. Apa hak perlindungan data Anda?
          </div>
          <div style={{ ...description }}>
            Kami ingin memastikan bahwa Anda mengetahui semua hak perlindungan data Anda. Anda berhak atas hal-hal seperti berikut: hak untuk mengetahui identitas pihak yang menerima atau meminta data, hak mengetahui tujuan permintaan data dan penggunaannya, mengetahui kewajiban pihak yang meminta data, meminta pelengkapan, pembaharuan, perbaikan data Anda, mengakhiri pemrosesan data Anda, menghapus data Anda dan/atau memusnahkan data Anda, mencabut persetujuan Anda atas Kebijakan Privasi ini atau persetujuan lainnya terhadap data pribadi Anda, menyampaikan keberatan atas tindakan pengambilan keputusan yang hanya didasarkan pada pemrosesan secara otomatis, termasuk pemrofilan, yang menimbulkan akibat hukum atau berdampak signifikan pada Anda, meminta kami secara proporsional untuk menunda atau membatasi pemrosesan data, mengakses data Anda, mengajukan klaim dan/atau menerima restitusi atas pelanggaran pihak yang memroses data Anda, meminta data Anda dikirimkan kepada pihak lain selama sistem kami dapat terhubung dengan aman dengan sistem pihak tersebut. Permintaan-permintaan ini akan di proses dalam jangka waktu 3 x 24 jam dan diajukan melalui permohonan tercatat secara elektronik ke <a href='mailto:media@electrum.id' target='_blank'>media@electrum.id</a> atau secara non elektronik.
            <br/><br/>
            Anda memahami bahwa kami dapat menolak permintaan Anda apabila permintaan tersebut membahayakan keamanan, kesehatan fisik atau mental Anda atau orang lain, berdampak pada pengungkapan data pribadi orang lain, dan/atau bertentangan dengan kepentingan pertahanan dan keamanan nasional.
            <br/><br/>
            Anda memahami dan menyetujui bahwa permohonan yang merubah data atau pemrosesan atas data dapat membuat kami tidak dapat memberikan Layanan. Dalam hal terdapat perubahan data atau pemrosesan yang mengakibatkan hal demikian, Anda menyetujui bahwa kami dapat mengakhiri Layanan kami kepada Anda.
          </div>
        </div>

        {/* SECTION H */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            H. Apa itu <i>cookies</i>
          </div>
          <div style={{ ...description }}>
            <i>Cookies</i> adalah file <i>text</i> yang ditempatkan di computer atau perangkat Anda untuk mengumpulkan informasi catatan internet pada umumnya dan informasi perilaku pengunjung. Apabila Anda mengunjungi <i>website</i>, aplikasi, atau Layanan kami, kami dapat mengumpulkan informasi dari Anda secara otomatis melalui <i>cookies</i> atau teknologi semacamnya.
          </div>
        </div>

        {/* SECTION I */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            I. Bagaimana kami menggunakan <i>cookies</i>
          </div>
          <div style={{ ...description }}>
            Kami menggunakan <i>cookies</i> dalam berbagai cara untuk mengembangkan pengalaman Anda di <i>website</i> kami, termasuk: menyimpan data <i>sign-in</i> Anda; memahami bagaimana Anda menggunakan <i>website</i> kami, termasuk untuk menawarkan produk-produk kami, afiliasi kami, atau rekanan kami.
          </div>
        </div>

        {/* SECTION J */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            J. Cookies seperti apa yang kami gunakan?
          </div>
          <div style={{ ...description }}>
            Ada berbagai macam jenis <i>cookies</i>, namun, website kami menggunakan:
            <ul style={{ ...ul, marginTop: '0.5rem' }}>
              <li>
                Fungsionalitas - kami menggunakan <i>cookies</i> ini agar kami dapat mengenali Anda dalam <i>website</i>, aplikasi, dan atau Layanan kami dan mengingat preferensi Anda sebelumnya. Hal ini dapat termasuk bahasa yang Anda pilih dan lokasi tempat Anda berada. Kami menggunakan <i>cookies</i> milik kami dan pihak ketiga.
              </li>
              <li>
                Periklanan - kami menggunakan <i>cookies</i> ini untuk mengumpulkan informasi mengenai kunjungan Anda di <i>website</i>, aplikasi, dan atau Layanan kami, konten yang Anda lihat, tautan yang Anda ikuti dan informasi pada <i>browser</i>, perangkat dan alamat IP Anda. Kami dapat membagikan aspek terbatas dari data-data ini kepada pihak ketiga untuk keperluan periklanan. Kami dapat juga membagikan data online yang dikumpulkan melalui <i>cookies</i> kepada mitra periklanan kami. Hal ini berarti apabila Anda mengunjungi website<i></i> lain, Anda dapat menerima iklan berdasarkan pola <i>browsing</i> Anda di website<i></i> kami.
              </li>
            </ul>
          </div>
        </div>

        {/* SECTION K */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            K. Bagaimana mengelola <i>cookies</i>
          </div>
          <div style={{ ...description }}>
            Anda dapat mengatur <i>browser</i> Anda untuk tidak menerima <i>cookies</i> dan <i>website</i> tersebut akan menjelaskan bagaimana cara menghilangkan <i>cookies</i> dari <i>browser</i> Anda. Namun, di beberapa kasus tertentu, beberapa fitur <i>website</i> kami akan tidak berjalan karena hal ini. Apabila Anda tidak menyetujui penggunaan <i>cookies</i>, mohon atur <i>browser</i> Anda agar tidak menerima <i>cookies</i>.
          </div>
        </div>

        {/* SECTION L */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            L. Kebijakan Privasi website lain
          </div>
          <div style={{ ...description }}>
            Website, aplikasi, atau Layanan kami dapat berisi tautan ke <i>website</i> lain. Kebijakan Privasi ini berlaku hanya untuk Layanan kami, apabila Anda mengklik tautan ke <i>website</i> atau aplikasi lain, Anda sebaiknya memeriksa kebijakan privasi <i>website</i> dan/atau aplikasi tersebut.
          </div>
        </div>

        {/* SECTION M */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            M. Perubahan pada Kebijakan Privasi kami
          </div>
          <div style={{ ...description }}>
            Kami akan meninjau Kebijakan Privasi kami secara berkala dan menempatkan setiap pembaharuan pada halaman ini. Kebijakan Privasi ini diperbaharui terakhir pada tanggal yang disebut di atas. Apabila terdapat perubahan pada Kebijakan Privasi ini, kami akan memberitahukannya kepada Anda. Dengan tetap menggunakan Layanan kami setelah pemberitahuan tersebut, Anda menyatakan bahwa Anda menyetujui perubahan Kebijakan Privasi tersebut.
          </div>
        </div>

        {/* SECTION N */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            N. Ketentuan bahasa
          </div>
          <div style={{ ...description }}>
            Kebijakan Privasi ini dapat dibuat dalam Bahasa Inggris dan Bahasa Indonesia. Apabila terdapat perbedaan interpretasi di antara keduanya, maka yang akan berlaku adalah versi Bahasa Indonesia.
          </div>
        </div>

        {/* SECTION O */}
        <div style={{ ...sectionGap }}>
          <div style={{ ...pointTitle, ...subSectionGap }}>
            O. Bagaimana cara menghubungi kami
          </div>
          <div style={{ ...description }}>
            Apabila Anda memiliki pertanyaan mengenai Kebijakan Privasi kami, Anda dapat menghubungi kami di: <a href='mailto:media@electrum.id' target='_blank'>media@electrum.id</a>.
          </div>
        </div>
      </div>

      <FooterMobile />
    </div>
  )
}

export default PrivacyPolicy
