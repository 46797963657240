import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { useGetNewsFromCMS } from 'apis/query'
import { ArrowLeft, ArrowRight } from 'assets/icons'
import { Footer, Header, HeaderB2B } from 'components'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const NewsDetail = () => {
  const { slug } = useParams()
  const navigate = useNavigate()

  const { data: newsData, isLoading } = useGetNewsFromCMS()

  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries('cms-news-list')
  }, [newsData])

  const currentNewsData = newsData && newsData?.find((item) => item.slug === slug)

  // OTHER NEWS
  const renderOtherNews = () => {
    return newsData?.filter((item) => item.slug !== currentNewsData.slug)?.map((item, index) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img loading='lazy' 
          alt={`news-${item.image}`}
          src={item.image}
          style={{
            width: '100%',
            height: '11.5rem',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '0.65rem 0.65rem 0 0',
          }}
        />
        <div style={{
          backgroundColor: '#F2F6F9',
          padding: '1.25rem',
          borderRadius: '0 0 0.65rem 0.65rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.75rem',
        }}>
          <div className='two-lines' style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: '#414141' }}>
            {item.title}
          </div>
          <div className='three-lines' style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#414141' }}>
            {item.description}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem' }}>
            <button
              onClick={() => navigate(`/news/detail/${item.slug}`, { state: { data: item } })}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.5rem 1rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              See News <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  if (isLoading || currentNewsData === undefined) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
    )
  }

  return(
    <div>
      <HeaderB2B />

      {/* DETAIL */}
      <div style={{ padding: '4rem 12.5rem' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '2.5rem' }}>
          <div
            onClick={() => navigate('/news')}
            style={{
              cursor: 'pointer',
              fontFamily: 'Gilroy-SemiBold',
              fontSize: '1.25rem',
              color: '#0B4571',
              display: 'flex',
            }}
          >
            <ArrowLeft fill="#fff" width='22' height='22' /> Back to News
          </div>
        </div>
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '3.125rem', marginBottom: '2rem' }}>
          {currentNewsData.title}
        </div>
        <img loading='lazy'  alt='news-img' src={currentNewsData.image} style={{ width: '100%', borderRadius: '1.5rem', marginBottom: '2rem' }} />
        <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.125rem', color: '#6A8192', marginBottom: '2rem' }}>
          <div
            style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.125rem', color: '#6A8192', }}
            contentEditable={false}
            dangerouslySetInnerHTML={{
              __html: currentNewsData.content,
            }}
          />
        </div>
        {currentNewsData.source !== "" && (
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.125rem', fontStyle: 'italic', marginBottom: '2rem' }}>
            Sumber artikel: <br />
            <a href={currentNewsData.source} target='_blank'>
              {currentNewsData.source}
            </a>
          </div>
        )}
      </div>

      {/* OTHER NEWS */}
      <div style={{ padding: '2rem 12.5rem 6rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '3.5rem', marginBottom: '2.5rem' }}>
          Berita Lainnya
        </div>
        <div style={{ width: '10rem', height: '1rem', backgroundColor: '#F04C3E', marginBottom: '3.75rem' }} />
        <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="large">
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', columnGap: '2rem', rowGap: '2rem' }}>
            {renderOtherNews()}
          </div>
        </Spin>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '3rem' }}>
          <button
            onClick={() => navigate('/news')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}
          >
            <ArrowLeft fill="#fff" /> Back to News
          </button>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default NewsDetail
