import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LanguageProvider, useIsMobile, ScrollToTop } from 'helpers'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Home,
  Product,
  Energy,
  GetYours,
  AboutUs,
  News,
  NewsDetail,
  Blog,
  BlogDetail,
  PrivacyPolicy,
  SepekanForm,
  ComingSoon,
  B2BHome,
  B2BBusiness,
} from 'pages'

import {
  MobileHome,
  MobileProduct,
  MobileEnergy,
  MobileGetYours,
  MobileAboutUs,
  MobileNews,
  MobileNewsDetail,
  MobileBlog,
  MobileBlogDetail,
  MobilePrivacyPolicy,
  MobileSepekanForm,
  MobileComingSoon,
  MobileB2BHome,
  MobileB2BBusiness,
} from 'mobile-pages'

const queryClient = new QueryClient()

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const App = () => {
  // MOBILE CHECK
  const isMobile = useIsMobile()

  // ROUTES
  const homeRoute = '/'
  const productsRoute = '/products/:type'
  const energyRoute = '/energy'
  const getYoursRoute = '/get-yours'
  const aboutUsRoute = '/about-us'
  const newsRoute = '/news'
  const newsDetailRoute = '/news/detail/:slug'
  const blogRoute = '/blog'
  const blogDetailRoute = '/blog/detail/:slug'
  const sepekanRoute = '/sepekan-form'
  const privacyPolicyRoute = '/privacy-policy'
  const b2bHomeRoute = '/b2b'
  const b2bBusinessRoute = '/b2b-business'

  // COUNTDOWN CHECKER
  const desiredDate = '2025-01-06' // Format: YYYY-MM-DD
  const [showCountdown, setShowCountdown] = useState(true)
  const calculateTimeLeft = () => {
    const difference = +new Date(desiredDate) - +new Date()
    let timeLeft = {}
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }
    return timeLeft
  }
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
  useEffect(() => {
    if (isObjectEmpty(timeLeft)) {
      setShowCountdown(false)
    } else {
      setShowCountdown(true)
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)
      return () => clearTimeout(timer)
    }
  })

  // DESKTOP ROUTES
  const desktopRoutes = () => {
    return (
      <Router>
        <LanguageProvider>
          <ScrollToTop />
          <Routes>
            {/* <Route path={homeRoute} element={<Home />} /> */}
            <Route path={homeRoute} element={<B2BHome />} />
            <Route path={productsRoute} element={<Product />} />
            <Route path={energyRoute} element={<Energy />} />
            <Route path={getYoursRoute} element={<GetYours />} />
            <Route path={aboutUsRoute} element={<AboutUs />} />
            <Route path={newsRoute} element={<News />} />
            <Route path={newsDetailRoute} element={<NewsDetail />} />
            <Route path={blogRoute} element={<Blog />} />
            <Route path={blogDetailRoute} element={<BlogDetail />} />
            <Route path={privacyPolicyRoute} element={<PrivacyPolicy />} />
            <Route path={sepekanRoute} element={showCountdown ? <ComingSoon /> : <SepekanForm />} />
            {/* <Route path={b2bHomeRoute} element={<B2BHome />} /> */}
            <Route path='/old-home' element={<Home />} />
            <Route path={b2bBusinessRoute} element={<B2BBusiness />} />
          </Routes>
        </LanguageProvider>
      </Router>
    )
  }

  // MOBILE ROUTES
  const mobileRoutes = () => {
    return (
      <Router>
        <LanguageProvider>
          <ScrollToTop />
          <Routes>
            {/* <Route path={homeRoute} element={<MobileHome />} /> */}
            <Route path={homeRoute} element={<MobileB2BHome />} />
            <Route path={productsRoute} element={<MobileProduct />} />
            <Route path={energyRoute} element={<MobileEnergy />} />
            <Route path={getYoursRoute} element={<MobileGetYours />} />
            <Route path={aboutUsRoute} element={<MobileAboutUs />} />
            <Route path={newsRoute} element={<MobileNews />} />
            <Route path={newsDetailRoute} element={<MobileNewsDetail />} />
            <Route path={blogRoute} element={<MobileBlog />} />
            <Route path={blogDetailRoute} element={<MobileBlogDetail />} />
            <Route path={privacyPolicyRoute} element={<MobilePrivacyPolicy />} />
            <Route path={sepekanRoute} element={showCountdown ? <MobileComingSoon /> : <MobileSepekanForm />} />
            {/* <Route path={b2bHomeRoute} element={<MobileB2BHome />} /> */}
            <Route path='/old-home' element={<MobileHome />} />
            <Route path={b2bBusinessRoute} element={<MobileB2BBusiness />} />
          </Routes>
        </LanguageProvider>
      </Router>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        autoClose={2000}
        position="top-center"
      />
      {isMobile ? mobileRoutes() : desktopRoutes()}
    </QueryClientProvider>
  )
}

export default App
