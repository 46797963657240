import { ArrowRight, ElectrumLogo } from 'assets/icons'
import { AboutUsKV2Mobile, AboutUsKVMobile, AboutUsSpirit, AboutUsSpiritMobile, AboutUsSpiritMobile2, ElectrumYear, Mileage, Mission1, Mission2, Mission3, ProvenQuality2, Riders, Showroom, Swaps } from 'assets/images'
import { Timeline } from 'assets/lottie'
import { FooterMobile, HeaderB2BMobile, HeaderMobile } from 'components'
import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'

const MobileAboutUs = () => {
  // COLORED TEXT
  const ColoredText = styled.span`
    background: linear-gradient(0deg, #0B4571, #1ECECA);
    color: transparent;
    background-clip: text;
    font-family: Gilroy-Bold;
    font-size: 1rem;
  `

  return (
    <div style={{ backgroundColor: '#EBF0F5' }}>
      <HeaderB2BMobile />

      {/* MAIN KV */}
      <div>
        <img loading='lazy'  alt='aboutus-kv' src={AboutUsKVMobile} style={{ width: '100%' }} />
      </div>

      {/* ABOUT US */}
      <div style={{ padding: '3rem 2rem 4rem 2rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', paddingLeft: '2rem', marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
          Tentang Electrum {' '}
          <span style={{ marginLeft: '1rem' }}>
            <ElectrumLogo height={30} />
          </span>
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', paddingLeft: '2rem', marginBottom: '2.5rem', borderLeft: '0.5rem solid #1ECECA' }}>
          Mengoptimalkan sinergi GoTo Group dan TBS Energi Utama, Electrum bergerak sebagai pemimpin dalam upaya elektrifikasi dan pembangunan ekosistem kendaraan listrik roda dua di Indonesia. Electrum memiliki komitmen untuk mengembangkan desain battery pack, sistem pertukaran baterai, stasiun swap, motor listrik, serta sistem IoT yang terintegrasi secara menyeluruh.
        </div>
        <img loading='lazy'  alt='rectangle-placeholder' src={AboutUsKV2Mobile} style={{ width: '100%' }} />
      </div>

      {/* MISSION */}
      <div style={{ padding: '3rem 2rem', backgroundColor: 'white', borderRadius: '1.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', marginBottom: '1.25rem' }}>
          Misi Electrum
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', lineHeight: '1.35' }}>
          Upaya kami untuk memberikan solusi transportasi bersih yang berkualitas didasari oleh niat pelayanan kami untuk menjaga lingkungan dan masyarakat di sekitar kami, serta berkontribusi bagi kemandirian energi Indonesia dan inisiatif iklim nasional. 
        </div>
        <div style={{ display: 'flex', overflowX: 'scroll', columnGap: '1.5rem', margin: '2.5rem 0 3.5rem 0' }}>
          <div style={{ width: '80%', flexShrink: '0' }}>
            <img loading='lazy'  src={Mission1} alt='howto1' style={{ width: '100%' }} />
          </div>
          <div style={{ width: '80%', flexShrink: '0' }}>
            <img loading='lazy'  src={Mission2} alt='howto1' style={{ width: '100%' }} />
          </div>
          <div style={{ width: '80%', flexShrink: '0' }}>
            <img loading='lazy'  src={Mission3} alt='howto1' style={{ width: '100%' }} />
          </div>
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', lineHeight: '1.35', marginBottom: '2.5rem' }}>
          Semangat Electrum adalah untuk mengkatalisasi pertumbuhan ekosistem kendaraan listrik di Indonesia, dan mendukung pengembangan sumber daya lokal guna memastikan solusi yang bermakna, relevan dan kredibel dalam membangun sistem mobilitas yang berkualitas dan terjangkau bagi bangsa dan generasi mendatang.
        </div>
        <img loading='lazy'  alt='aboutus-spirit' src={AboutUsSpiritMobile2} style={{ width: '100%' }} />
      </div>

      {/* ELECTRUM BY YEAR */}
      <div style={{ margin: '3rem 0' }}>
        <div style={{ backgroundColor: 'white', borderRadius: '1.5rem', padding: '3rem 0rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', padding: '0rem 2rem', marginBottom: '0.5rem' }}>
            Electrum dalam Tahun
          </div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: Timeline,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            style={{
              width: '100%',
            }}
          />
        </div>
      </div>

      {/* TRUST */}
      <div style={{ padding: '0rem 2rem 0rem 2rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', textAlign: 'center' }}>
          Terbukti dan Terpercaya
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', textAlign: 'center', marginTop: '1.5rem' }}>
          Electrum berhasil membuktikan sebagai penyedia solusi mobilitas roda dua yang berkualitas, efisien, dan ramah lingkungan.
        </div>
        <img loading='lazy'  src={ProvenQuality2} alt='proven-quality' style={{ width: '100%', marginTop: '1.5rem' }} />
      </div>

      {/* DETAIL CARDS */}
      <div style={{ backgroundColor: 'white', borderRadius: '1.5rem', marginTop: '-0.85rem' }}>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2.25rem' }}>
          <div style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 3px 15px 0px rgba(60, 223, 219, 1)',
            padding: '1.25rem',
            borderRadius: '1.25rem',
            display: 'flex',
            columnGap: '2rem',
            rowGap: '1rem',
            flexWrap: 'wrap',
            justifyContent: 'center',
            margin: '0rem 2rem',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                3.000+
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Motorcycle OTR
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                20 million
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Kilometer Journey
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                250
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Total BSS
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                1 GWh
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Electricity Consumption
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                830 ton
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                CO2e Savings
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: '3rem 2rem', display: 'flex', overflowX: 'scroll', columnGap: '2rem' }}>
          <img loading='lazy'  alt='mileage' src={Mileage} style={{ width: '90%', flexShrink: 0 }} />
          <img loading='lazy'  alt='riders' src={Riders} style={{ width: '90%', flexShrink: 0 }} />
          <img loading='lazy'  alt='swaps' src={Swaps} style={{ width: '90%', flexShrink: 0 }} />
        </div>
      </div>

      {/* CENTER */}
      <div style={{ padding: '3rem 2rem', marginBottom: '3rem', backgroundColor: 'white', borderRadius: '1.5rem', marginTop: '3rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', marginBottom: '1rem' }}>
          Showroom & Service Center
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', marginBottom: '1.5rem' }}>
          Kunjungi Electrum untuk rasakan ekosistem kendaraan motor listrik terlengkap.
        </div>
        <img loading='lazy'  alt='showroom' src={Showroom} style={{ width: '100%', marginBottom: '1.5rem' }} />
        {/* RIGHT */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.125rem', color: '#103856' }}>
            Alamat Lengkap Showroom
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
            Jl. Sultan Iskandar Muda No.Kav.29, RT.7/RW.9, Pondok Indah, Kec. Kby. Lama, Jakarta Selatan 12240
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
            Senin-Jumat	09.00-17.00 <br />
            Sabtu 09.00-13.00 <br />
            Minggu Libur
          </div>
          <div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
            >
              Hubungi Electrum <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>

      {/* DRIVER RENT */}
      <div style={{ margin: '3rem 2rem', padding: '1.5rem', backgroundColor: 'white', borderRadius: '0.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', marginBottom: '1rem' }}>
          Sewa Khusus Mitra Driver
        </div>
        <div style={{ fontFamily: 'Gilroy-Semibold', fontSize: '0.75rem', color: '#71869C', marginBottom: '2rem' }}>
          Dapatkan penawaran khusus untuk mitra driver ojek online melalui skema penyewaan bulanan motor listrik Electrum.
        </div>
        <div>
          <button
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}
            onClick={() => window.open('https://opsapp.id/registrasi_electrum', '_blank')}
          >
            See More <ArrowRight fill="#fff" />
          </button>
        </div>
      </div>

      <FooterMobile />
    </div>
  )
}

export default MobileAboutUs
